import {actionTypes} from 'redux-resource';

/* header-actions */
export const SET_TABS = 'SET_TABS';
export const RESET_TABS = 'RESET_TABS';
export const SET_TITLE = 'SET_TITLE';
export const RESET_TITLE = 'RESET_TITLE';

export const setTabs = tabs => ({type: SET_TABS, tabs});
export const resetTabs = () => ({type: RESET_TABS});

export const setTitle = title => ({type: SET_TITLE, title});
export const resetTitle = () => ({type: RESET_TITLE});

/* custom layout actions */
export const DISABLE_DEFAULT_LAYOUT = 'DISABLE_DEFAULT_LAYOUT';
export const ENABLE_DEFAULT_LAYOUT = 'ENABLE_DEFAULT_LAYOUT';

export const DISABLE_FOOTER = 'DISABLE_FOOTER';
export const ENABLE_FOOTER = 'ENABLE_FOOTER';

/** Disable header and footer rendering.
 *  Main container is full height.
 *  @see components/client/layout/layout.jsx for details */
export const disableDefaultLayout = () => ({type: DISABLE_DEFAULT_LAYOUT});

/** Enable header and footer rending.
 *  Main container is not full height.
 *  @see components/client/layout/layout.jsx for details */
export const enableDefaultLayout = () => ({type: ENABLE_DEFAULT_LAYOUT});

/** Disable footer rendering.
 *  @see components/client/layout/layout.jsx for details */
export const disableFooter = () => ({type: DISABLE_FOOTER});

/** Enable footer rendering.
 *  @see components/client/layout/layout.jsx for details */
export const enableFooter = () => ({type: ENABLE_FOOTER});

// TODO refactor
// we don't need to use redux here, it will be better to create data-provider
// for footer which will make requests without redux.
export const getTotalSalesCountCurrentYear = () => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	postAction: 'getTotalSalesCountCurrentYear',
	resourceType: 'totalSalesCountCurrentYear',
	request: 'read',
	url: 'sale/count',
	postTransform: body => ({
		id: 0,
		...body
	})
});
