import {ReportUrlBuilder} from '../../api/url-builder';
import {HttpMethods} from '../../domain/http';

export const GET_AGENT_REPORT = 'GET_AGENT_REPORT';
export const getAgentReports = agentId => ({
	type: GET_AGENT_REPORT,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: ReportUrlBuilder.getAgentReports(agentId)
		},
		postprocess: action => {
			const {
				http: {response: {body}}
			} = action;

			action.data = {content: body};
			return action;
		}
	}
});
