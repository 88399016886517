import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

const Notification = ({open, message, hide}) => {
	if (!message) {
		return null;
	}

	const messageNode = Array.isArray(message)
		? message.join(', ')
		: message;

	return (
		<Snackbar
			autoHideDuration={4000}
			open={open}
			message={messageNode}
			onClose={hide}
		/>
	);
};

Notification.propTypes = {
	open: PropTypes.bool.isRequired,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	hide: PropTypes.func.isRequired
};

export default Notification;
