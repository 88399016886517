import {TracfinFields, TracfinBuyerType} from '../../domain/tracfin';

export default ({
  "commission-progress-bar": {
    "pack-gold-title": "Mes encaissements sur cette période - <1><0>PACK GOLD</0></1> gratuit les 3 mois suivants si montant supérieur à 9900€ HT",
    "tracking-disabled": "Votre barre de progression va commencer à partir de <1>!#!month!#!</1>."
  },
  "sales-admin": {
    "export-to-excel": "Exporter vers Excel"
  },
  "sales-page": {
    "status-filter": {
      "button": "Filtre"
    }
  },
  "sale-form": {
    "regular": "Moi uniquement",
    "sharedInHouse": "Moi avec un autre conseiller Sextant",
    "sharedWithAgency": "Vente avec une autre agence",
    "fields": {
      "agent": "Conseiller",
      "mandateNumber": "Mandat",
      "price": "Prix FAI",
      "commission": "Commission, TTC",
      "commissionPayer": "Commission payée par",
      "seller": "Vendeur(s): Prenom(s) et Nom(s)",
      "sellerEmail": "Vendeur(s): Email",
      "buyer": "Acheteur(s): Prenom(s) et Nom(s)",
      "buyerEmail": "Acheteur(s): Email",
      "sellerDetails": "Coordonnées du vendeur",
      "sellerCountry": "Pays du vendeur",
      "sellerAddress": "Adresse du vendeur",
      "sellerPostcode": "Code postal du vendeur",
      "sellerTown": "Ville du vendeur",
      "buyerDetails": "Coordonnées de l'acheteur",
      "buyerCountry": "Pays de l'acheteur",
      "buyerAddress": "Adresse de l'acheteur",
      "buyerPostcode": "Code postal de l'acheteur",
      "buyerTown": "Ville de l'acheteur",
      "notary": "Notaire",
      "propertyType": "Type de propriété",
      "propertyPostalCode": "Code postal de propriété",
      "town": "Ville",
      "mandateDate": "Date du mandat",
      "address": "Adresse de propriété"
    },
    "noOptions": "Aucune option",
    "placeholders": {
      "coOwner": "Tapez pour trouver conseiller",
      "coOwnerAgency": "Agence concurrente"
    },
    "hints": {
      "rate": "Vous aurez <1>!#!ownerRate!#!%</1> de commission, <3>!#!coOwnerName!#!</3> obtiendra <5>!#!coOwnerRate!#!</5>%",
      "coOwnerPlaceholder": "Autre conseiller",
      "coOwnerAgencyPlaceholder": "Agence concurrente",
      "startBoostActivated": "Félicitation! Rétrocession à 95% sur cette vente!"
    },
    "values": {
      "seller": "Vendeur",
      "buyer": "Acheteur"
    },
    "submitPopup": {
      "title": "Il y a déjà vente avec le même numéro de mandat.",
      "content": "Êtes-vous sûr de vouloir mettre à jour une vente avec un numéro de mandat dupliqué ?",
      "try-again": "Nous sommes désolés, mais quelque chose a mal fonctionné. Veuillez réessayer plus tard ou contacter le support technique",
      "ok": "Ok"
    }
  },
  "agent-network-admin": {
    "header": "Réseau de conseiller"
  },
  "sale-details": {
    "mySale": "Ma vente",
    "sale": "Vente",
    "status": "statut",
    "byAgent": "Par conseiller",
    "sharingInfo": "<0><0>!#!rate!#!%</0></0> de commission est partagé avec <2><0>!#!sharedWith!#!</0></2>",
    "myCommission": "Ma commission",
    "invoiceButton": "Facture",
    "cancelButton": "Annuler la vente",
    "successMessage": {
      "message": "Envoi réussi",
      "ok": "OK"
    },
    "cancellation-confirmation-dialog": {
      "title": "Annuler la vente ?",
      "content": "Êtes-vous sûr(e) de vouloir annuler cette vente ? Seul un administrateur peut inverser ce changement",
      "back": "Non",
      "confirm": "Oui"
    },
    "delete-confirmation-dialog": {
      "title": "Supprimer la vente",
      "content": "Êtes-vous sûr de vouloir supprimer cette vente ?",
      "success": "La vente a été supprimée avec succès",
      "back": "Non",
      "confirm": "Oui",
      "ok": "Ok",
      "deleteSaleButton": "Supprimer la vente",
      "try-again": "Nous sommes désolés, mais quelque chose a mal fonctionné. Veuillez réessayer plus tard ou contacter le support technique"
    },
    "startBoostRate": "Vente Welcome Bonus: !#!rate!#!% de rétrocession.",
    "startBoostEndDate": "Si vente clôturée avant le !#!date!#!",
    "fields": {
      "mandateNumber": "Mandat",
      "mandateDate": "Date du mandat",
      "price": "Prix FAI",
      "commission": "Commission TTC",
      "commissionPayer": "Commission payée par",
      "seller": "Vendeur",
      "buyer": "Acheteur",
      "notary": "Notaire",
      "propertyType": "Type de propriété",
      "propertyAddress": "Adresse de la propriété",
      "propertyPostalCode": "Code postal de propriété",
      "town": "Ville"
    },
    "processingFields": {
      "status": "Statut",
      "paymentType": {
        "empty": "A remplir svp",
        "title": "Type de paiement",
        "mortgage": "100% emprunt bancaire",
        "cash": "100% comptant/cash",
        "creditAndDeposit": "Mix emprunt + apport"
      },
      "sourceOfFunds": {
        "empty": "A remplir svp",
        "title": "Origine des fonds",
        "saving": "Epargne",
        "lifeInsurance": "Assurance vie",
        "capitalGains": "Plus values mobilières (actions/crypto)",
        "disposalEstate": "Cession de biens immobiliers",
        "donation": "Donation",
        "heritage": "Héritage",
        "gambling": "Gains aux jeux",
        "dividends": "Dividendes",
        "others": "Autres"
      },
      "paymentDate": "Date de paiement",
      "compromis": "Signature du compromis",
      "compromisDate": {
        "title": "Signature compromis\u00A0/\u00A0promesse",
        "field": "Date du compromis"
      },
      "compromisDocument": {
        "title": "Fichier compromis\u00A0/\u00A0promesse",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)",
        "infoModal": {
          "title": "Attention",
          "text": "SVP n'oubliez pas de remplir les informations LCB-FT Tracfin ci-dessus",
          "button": "D'accord"
        }
      },
      "commissionDistribution": {
        "title": "Répartition % commissions (ventes inter-mandataires)",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)"
      },
      "deedOfSale": "Signature de l'acte de vente",
      "deedOfSaleDate": {
        "title": "L'acte de vente",
        "field": "Date de l'acte de vente"
      },
      "proformaInvoice": {
        "title": "Facture proforma",
        "create": "Créer",
        "created": "Créé",
        "createdDate": "Date de création",
        "prerequisitesErrors": "Les champs suivants doivent comporter des données valides pour générer facture proforma :",
        "invoiceForbiddedOffer": "La facture proforma ne peut pas être crée pour l'offre de vente",
        "invoiceForbiddedCancelled": "La facture proforma ne peut pas être crée pour une vente annulée",
        "invoiceAlreadyGeneratedOpenToChanges": "Tout changement de la vente éditera une nouvelle facture proforma",
        "invoiceAlreadyPaid": "La facture proforma oest payée",
        "invoiceAlreadyGenerated": "La facture proforma est générée",
        "invoiceCanBeGenerated": "La facture proforma peut être générée",
        "invoiceCanBeGeneratedOn": "La facture proforma sera créée après l'envoi",
        "invoiceIsClosedToModifications": "La facture proforma ne peux pas être modifiée",
        "invoiceNotCompromis": "La facture proforma ne peut être créée que pour la vente avec compromis",
        "prerequisitesApproved": "Vous n'avez rien à faire, veuillez attendre que l'administrateur vérifie la vente et crée une facture"
      },
      "notaryInvoice": {
        "title": "Facture",
        "create": "Créer",
        "paymentDate": "Date de paiement",
        "paid": "Payé\u00A0?",
        "prerequisitesErrors": "Les champs suivants doivent comporter des données valides pour générer facture :",
        "helperText": {
          "invoiceNotSold": "La facture ne peut être créée que pour les ventes vendues",
          "invoiceCanBeGenerated": "La facture peut être créée",
          "invoiceEditable": "La date de paiement modifiée entraînera la mise à jour de la facture du notaire",
          "invoiceIsClosedToModifications": "Non modifiable"
        }
      },
      "certificationDocument": {
        "title": "Attestation de vente",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)"
      },
      "agentInvoice": {
        "title": "Facture conseiller",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)"
      },
      "sruDelayPurged": "Délai SRU purgé",
      "generateInvoice": "Créer une facture proforma",
      "backedByBritishTeam": "Vente via l'équipe anglaise Sextant",
      "approved": "Approuvé?",
      "signed": "Signé?",
      "rejectReason": "Rejeter la raison",
      "noFile": "Pas de fichier",
      "notVerified": "Non vérifié",
      [TracfinFields.SELLER]: "LCB-FT Tracfin Vendeur(s)",
      [TracfinFields.BUYER]: {
        "title": "LCB-FT Tracfin Acquéreur(s)",
        "text": "Identité(s) acquéreur(s)\n& Questionnaires",
        [TracfinFields.BUYER_COMPANY_KEY]: {
          "title": "Acquéreur - Personne Morale : !#!name!#!"
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_KEY]: {
          "title": "Bénéficiaire !#!count!#! - Personne Morale",
        },
        [TracfinFields.BUYER_INDIVIDUAL_KEY]: {
          "title": "Acquéreur !#!count!#! - Personne Physique : !#!name!#!",
        },
        [TracfinFields.MYNOTARY_DOCUMENT]: {
          "question": "LCB-FT-Tracfin a t-elle était\ncomplétée dans MyNotary?",
          "title": "Formulaire LCB-FT Tracfin - MyNotary\nVigilance Permanente & Renforcée",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_KBIS]: {
          "title": "KBIS",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_STATUTES]: {
          "title": "Statuts",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_OWNER]: {
          "title": "Bénéficiaire(s) effectif(s) - document de l’INPI",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_PASSPORT]: {
          "title": "Pièce(s) d'identité(s) ou passeport(s) - Bénéficiaire(s) effectif(s)",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_ADDRESS]: {
          "title": "Autres (Justificatif de domicile, pouvoirs du représentant légal...)",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_INDIVIDUAL_PASSPORT]: {
          "title": "Pièce d’identité / Passeport",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_INDIVIDUAL_PASSPORT_ADDITIONAL]: {
          "title": "Pièce d’identité / Passeport Verso",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_INDIVIDUAL_PROOF_OF_ADDRESS]: {
          "title": "Autres (Justificatif de domicile...)",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.VIGILANCE]: {
          "title": "Formulaire LCB-FT Tracfin\nVigilance Permanente acquéreurs",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)",
          "download": "$t(controls:upload-form.download)"
        },
        [TracfinFields.ENHANCED_VIGILANCE]: {
          "title": "Formulaire LCB-FT Tracfin\nVigilance Renforcée acquéreurs",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)",
          "download": "$t(controls:upload-form.download)"
        },
        [TracfinFields.ENHANCED_VIGILANCE_REQUIRED]: {
          "text": "Cette vente a-t-elle besoin d’une Vigilance Renforcée?",
          "button": "Activer vigilance renforcée"
        },
        "add-new-modal": {
          "title": "Ajouter un nouvel acquéreur",
          "text": {
            [TracfinBuyerType.INDIVIDUAL]: "Personne Physique : Prénom & Nom",
            [TracfinBuyerType.COMPANY]: "Personne Morale : Désignation",
            [TracfinBuyerType.EMPTY]: ""
          },
          "confirm": "Enregistrer"
        },
        "add-person": "AJOUTER ACQUÉREUR +",
        "add-beneficiary": "AJOUTER BÉNÉFICIAIRE +",
        "individual-or-company": {
          "text": "Définir si personne physique\nou personne morale",
          [TracfinBuyerType.INDIVIDUAL]: "PERSONNE PHYSIQUE",
          [TracfinBuyerType.COMPANY]: "PERSONNE MORALE"
        },
        "info-links": {
          "gafi": "SITE GAFI",
          "assets-freeze": "LISTE GELS DES AVOIRS",
          "inpi": "SITE INPI",
        },
        "reset": {
          "title": "Réinitialiser section acquéreur",
          "text": "Êtes-vous sûr de vouloir réinitialiser cette section acquéreur?\n(Les fichiers téléversés seront perdus!)"
        },
        "remove": {
          "title": "Supprimer l'acheteur",
          "text": "Voulez-vous vraiment supprimer !#!name!#!?\n(Les fichiers téléchargés seront perdus!)"
        },
        "none": {
          "title": "",
          "text": ""
        },
        "approve": {
          "title": "Agréer la rubrique acheteur Tracfin",
          "text": "Êtes-vous sûr de vouloir valider l'espace acheteur Tracfin?\n(Tous les rejets et messages seront perdus!)"
        }
      },
      "uploadInfoModal": {
        "title": "Extensions de fichiers autorisées",
        "text": "Les extensions .jpg, .png, .pdf et .zip sont prises en charge.\nVeuillez utiliser .pdf ou .zip si la pièce jointe de plusieurs pages est nécessaire",
        "confirm": "Ne plus afficher"
      },
      "filePreview": {
        "fileIsNotSupported": "L'extension \"!#!ext!#!\" n'est pas prise en charge. Veuillez télécharger pour prévisualiser.",
        "zip": "Il s'agit d'un fichier .zip. Veuillez le télécharger pour le prévisualiser."
      }
    },
    "values": {
      "seller": "Vendeur",
      "buyer": "Acheteur",
      "yes": "Oui",
      "no": "Non",
      "generateInvoice": "Créer une facture"
    },
    "compromis": {
      "compromisDocumentTitle": "Compromis",
      "previewCompromis": "Aperçu du document",
      "previewCompromisDisabled": "Aucun document téléchargé",
      "dropzoneLabel": "Ajouter un document",
      "download": "Téléchargement"
    },
    "submitMessage": {
      "success": "Les détails de la vente sont mis à jour"
    }
  },
  "owner-level": {
    "text": "Niveau"
  },
  "add-new-godson": {
    "title": "Ajouter un nouveau filleul",
    "descriptionOne": "Vous souhaitez étendre votre réseau\u00A0?",
    "descriptionTwo": "Suivez ces quelques étapes et nous étudierons la candidature de votre filleul potentiel.",
    "firstName": "Prénom",
    "lastName": "Nom",
    "email": "Email",
    "phone": "Téléphone",
    "selectsLabel": {
      "commercial": "A-t-il/elle une expérience commerciale\u00A0?",
      "estate": "A-t-il/elle une expérience dans l'immobilier\u00A0?",
      "yes": "Oui",
      "no": "Non"
    },
    "buttons": {
      "next": "Suivant",
      "back": "Retour",
      "send": "Envoyer"
    },
    "errorMessage": {
      "message": "Erreur !",
      "description": "Quelque chose a mal fonctionné"
    },
    "successMessage": "<0>Félicitations !</0><1/>Votre candidature pour<3/><4/><5><0>!#!name!#!</0></5><6/>a été envoyé. Nous vous contacterons dans les plus brefs délais."
  },
  "sales-type": {
    "all": "Toutes",
    "my": "Mes ventes",
    "mentees": "Les ventes de mes filleuls"
  },
  "invoices-type": {
    "proforma": "Proforma",
    "notary": "Notaire",
    "sextant": "Sextant",
    "mentee": "Filleul"
  },
  "invoices-paid-notary": {
    "both": "Toutes",
    "yes": "Payé",
    "no": "Impayé"
  },
  "invoices-paid-sextant": {
    "both": "All",
    "yes": "Payé",
    "no": "Impayé"
  },
  "send-invoice": {
    "title": "Envoyer la facture à l'email",
    "send": "Envoyer",
    "cancel": "Annuler",
    "great": "Ok",
    "success": "<0><0>La facture </0></0><1/><2><0>!#!invoiceNumber!#!</0></2><3/>a été envoyée à l'adresse e-mail :<5/><6><0>!#!email!#!</0></6>",
    "notPaidNotary": "Désolé, mais vous ne pouvez pas envoyer votre facture tant que la vente n'a pas été payée par le notaire."
  },
  "event-feed": {
    "details": "Détail",
    "like": "J'aime",
    "delete": "Supprimer",
    "comment": "Commenter",
    "addEvent": "Créer un nouvel évènement",
    "addComment": "Laisser un commentaire"
  },
  "sales-summary": {
    "total": "Total",
    "totalCountSingle": "<0>!#!totalCount!#!</0> vente",
    "totalCountMultiple": "<0>!#!totalCount!#!</0> ventes"
  },
  "family-tree": {
    "add-button-tooltips": {
      "n3-expand": "Pour ajouter 3 conseillers sur votre 1er niveau, l'un de vos filleuls doit avoir 1 filleul"
    }
  },
  "detach-agent": {
    "tooltip": "Détacher cet agent",
    "title": "Détacher !#!agentName!#! du mentor (!#!parentName!#!)",
    "text": "Vous allez détacher cet agent du mentor. Cette action est irréversible.",
    "detach": "Détacher cet agent du mentor"
  }
});
