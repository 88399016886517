import {actionTypes} from 'redux-resource';
import {EventsUrlBuilder} from '../../api/url-builder';
import {HttpMethods} from '../../domain/http';
import {REORDER_RESOURCE} from './resource-commands';

const resourceType = 'event';

export const VIEW_EVENT_FEED = 'VIEW_EVENT_FEED';
export const GET_UNREAD_EVENTS = 'GET_UNREAD_EVENTS';

export const viewEventFeed = () => ({
	type: VIEW_EVENT_FEED,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: EventsUrlBuilder.viewFeed()
		}
	}
});

export const getUnreadEventsCount = () => ({
	type: GET_UNREAD_EVENTS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: EventsUrlBuilder.unreadCount()
		},
		postprocess: action => {
			const {http: {response: {body}}} = action;

			action.data = body.count;
			return action;
		}
	}
});

export const deleteEvent = id => ({
	type: 'DELETE_EVENT',
	id
});

export const deleteEventComment = (eventId, commentId) => ({
	type: 'DELETE_EVENT_COMMENT',
	eventId,
	commentId
});

export const addEventComment = (eventId, userId, text, prefix = '') => ({
	type: actionTypes.CREATE_RESOURCES_PENDING,
	resourceType,
	request: 'createComment',
	postAction: 'createEventComment',
	url: 'comment',
	urlPrefix: prefix,
	data: {
		eventId,
		userId,
		text
	}
});

export const addEvent = (id, text) => ({
	type: actionTypes.CREATE_RESOURCES_PENDING,
	resourceType,
	request: 'create',
	postAction: 'createAdminEvent',
	list: 'all',
	urlPrefix: 'admin/',
	url: 'event',
	data: {
		adminId: id,
		text
	}
});

export const setEventLike = (eventId, requestPrefix, urlPrefix = '') => ({
	type: actionTypes.UPDATE_RESOURCES_PENDING,
	resourceType,
	request: `updateEventLike${requestPrefix}`,
	method: 'post',
	postAction: 'updateEventLike',
	url: `event/${eventId}/like`,
	urlPrefix
});

export const deleteEventLike = (eventId, requestPrefix, urlPrefix = '') => ({
	type: actionTypes.UPDATE_RESOURCES_PENDING,
	resourceType,
	request: `updateEventLike${requestPrefix}`,
	method: 'delete',
	postAction: 'updateEventLike',
	url: `event/${eventId}/like`,
	urlPrefix
});

export const readEvents = ({
	prefix = '', pageNumber, pageSize, orderBy, orderDirection
} = {}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'read',
	list: 'all',
	mergeListIds: false,
	requestDetails: {
		paged: !!pageSize,
		ordered: !!orderBy
	},
	urlPrefix: prefix,
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection
	}
});

export const readUnreadEvents = ({
	prefix
} = {}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	mergeListIds: true,
	request: 'read',
	list: 'all',
	requestDetails: {},
	urlPrefix: prefix,
	data: {
		unreadOnly: true
	}
});

export const readMoreEvents = ({
	prefix, pageNumber, pageSize, orderBy, orderDirection
} = {}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	mergeListIds: true,
	request: 'read',
	list: 'all',
	requestDetails: {
		paged: !!pageSize,
		ordered: !!orderBy
	},
	urlPrefix: prefix,
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection
	}
});

export const reorderEvents = () => ({
	type: REORDER_RESOURCE,
	comparator: (x, y) => new Date(y.timestamp) - new Date(x.timestamp),
	resourceType,
	skipResourceMiddleware: true,
	list: 'all'
});

