const STORAGE_NAME = 'backUrl';
const EXPIRES_MILLISECONDS = 1000 * 60 * 2; // now + 2 minutes
const EXPIRES_SOON = 500; // now + 500 ms

const getCurrentTime = () => new Date().getTime();
const clear = () => sessionStorage.removeItem(STORAGE_NAME);
const get = () => {
	const value = sessionStorage.getItem(STORAGE_NAME);
	const parsed = value && JSON.parse(value);

	if (!parsed) {
		return false;
	}

	if (getCurrentTime() > new Date(parsed.expiresAt)) {
		clear();
		return false;
	}

	return parsed.url;
};

const set = (url, expiresAt) => sessionStorage.setItem(STORAGE_NAME, JSON.stringify({
	expiresAt: expiresAt || getCurrentTime() + EXPIRES_MILLISECONDS,
	url
}));

const getAndClearSoon = () => {
	const url = get();

	if (!url) {
		return false;
	}

	// set new expiring time to avoid wrong redirects on '/' but delay for multi redirects in progress of sign-in;
	// otherwise '/' will redirect to the backUrl all in expire time (2 minutes)
	set(url, getCurrentTime() + EXPIRES_SOON);
	return url;
};

const setOnce = url => {
	const currentUrl = get();

	if (!currentUrl) {
		set(url);
	}
};

export default {
	get,
	set,
	setOnce,
	getAndClearSoon
};
