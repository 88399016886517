import PropTypes from 'prop-types';

const OFFER = 'offer';
const COMPROMIS = 'compromis';
const SOLD = 'sold';
const CANCELLED = 'cancelled';
const asArray = [OFFER, COMPROMIS, SOLD, CANCELLED];

export default ({
	OFFER,
	COMPROMIS,
	SOLD,
	CANCELLED,
	asArray,
	propType: PropTypes.oneOf(asArray)
});

