import makeCamelCase from '../strings/kebab-case-to-camel-case';

const NUMERIC_HEADERS = [
	'pagingTotal',
	'pagingLimit',
	'pagingOffset'
];

function convertHeaderValue(key, value) {
	return NUMERIC_HEADERS.find(k => k === key) ? parseFloat(value) : value;
}

export default function parseHeaders(headers) {
	return Object.keys(headers)
		.reduce((result, key) => {
			const normalizedKey = key.toLowerCase();

			if (normalizedKey.indexOf('x-') === 0) {
				const header = makeCamelCase(normalizedKey.substr(2));

				result[header] = convertHeaderValue(header, headers[key]);
			} else if (key.startsWith('content')) {
				const header = makeCamelCase(normalizedKey);

				result[header] = headers[key];
			}

			return result;
		}, {});
}

