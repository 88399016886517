import PropTypes from 'prop-types';

const SUPERUSER = 'superuser';
const AGENT = 'agent';
const ADMIN = 'admin';
const KEROS_ADMIN = 'kerosAdmin';
const asArray = [AGENT, ADMIN, KEROS_ADMIN, SUPERUSER];

function hasSuperuserRole(roles) {
	return roles.includes(SUPERUSER);
}

function hasAdminRole(roles) {
	return roles.includes(ADMIN);
}

function hasKerosAdminRole(roles) {
	return roles.includes(KEROS_ADMIN);
}

function hasAgentRole(roles) {
	return roles.includes(AGENT);
}

export default ({
	AGENT,
	SUPERUSER,
	KEROS_ADMIN,
	ADMIN,
	asArray,
	hasSuperuserRole,
	hasAdminRole,
	hasKerosAdminRole,
	hasAgentRole,
	propType: PropTypes.oneOf(asArray)
});
