import {
	READ_SUPPORT_LIST
} from '../actions/support';

const initialState = [];

export default (state = initialState, action) => {
	switch (action.type) {
		case READ_SUPPORT_LIST:
			return action.http.response.body;
		default:
			return state;
	}
};
