import has from 'lodash/has';
import get from 'lodash/get';

export default functionPath => {
	if (!Array.isArray(functionPath)) {
		throw new Error(`functionPath: expected array, got: ${functionPath}`);
	}

	return () => next => action => {
		if (!has(action, functionPath)) {
			return next(action);
		}

		const process = get(action, functionPath);

		if (typeof process !== 'function') {
			throw new Error(`preprocess is a not a function, action-type: ${action.type}`);
		}

		return next(process(action));
	};
};
