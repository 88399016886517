/* eslint-disable no-console */
import oidc from 'oidc-client';
import request from 'superagent';
import {retryOnErrorAsync} from "./retry-on-error";

// logging
oidc.Log.logger = console;
oidc.Log.level = oidc.Log.INFO;

const START_DATE = new Date();
const getConsoleTime = () => {
	const END_TIME = new Date() - START_DATE;

	const minutes = Math.floor(END_TIME / 1000 / 60);
	const seconds = Math.floor(END_TIME / 1000) - (minutes * 60);

	return `${minutes}m ${seconds}s`;
};

let USER_INFO_URL = `${process.env.REACT_APP_SSO_AUTHORITY}/connect/userinfo`;
const KEY_AUTH_TOKEN = 'keros_authtoken';
const KEY_SKIP_LANDING = 'keros_skiplanding';
const INITIAL_TOKEN = localStorage.getItem(KEY_AUTH_TOKEN) || null;
const INITIAL_SKIP_LANDING = !!localStorage.getItem(KEY_SKIP_LANDING) || false;

const oidcConfig = {
	client_id: 'keros',
	response_type: 'id_token token',
	scope: 'openid profile api1',
	authority: process.env.REACT_APP_SSO_AUTHORITY,
	redirect_uri: `${process.env.REACT_APP_BASE_URL}/signin-callback`,
	post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}`,
	loadUserInfo: false,
	revokeAccessTokenOnSignout: true,
	silent_redirect_uri: `${process.env.REACT_APP_BASE_URL}/silent-signin-callback`,
	accessTokenExpiringNotificationTime: 300, // 5 minutes before the end of access token life. token life: 1 hour
	automaticSilentRenew: false
};

if (process.env.NODE_ENV === 'production') {
	USER_INFO_URL = `${window.ssoConfiguration.identityServer}/connect/userinfo`;
	oidcConfig.authority = window.ssoConfiguration.identityServer;
	oidcConfig.redirect_uri = `${window.ssoConfiguration.baseUrl}/signin-callback`;
	oidcConfig.post_logout_redirect_uri = window.ssoConfiguration.baseUrl;
	oidcConfig.silent_redirect_uri = `${window.ssoConfiguration.baseUrl}/silent-signin-callback`;
}

const oidcProvider = new oidc.UserManager(oidcConfig);

window.oidcProvider = oidcProvider;

const user = {
	token: INITIAL_TOKEN,
	skipLanding: INITIAL_SKIP_LANDING
};

// used on my family tree page at least...
window.userInfo = user;

const setUser = ({
	id, firstName = '', lastName = '', email = '', avatar, roles, vatNumber
}) => {
	user.id = parseInt(id, 10);
	user.firstName = firstName;
	user.lastName = lastName;
	user.email = email;
	user.avatar = avatar;
	user.vatNumber = vatNumber;
	user.roles = roles.split(',');
};

const logOut = async () => {
	user.token = null;
	localStorage.removeItem(KEY_AUTH_TOKEN);
	await oidcProvider.signoutRedirect();
};

const logIn = () => oidcProvider.signinRedirect();

function queryUserInfo() {
	const req = request.get(USER_INFO_URL);

	req.set('Authorization', `Bearer ${user.token}`);
	req.query();

	return req;
}

let silentSignInInProgress = false;
async function doSilentSignin() {
	if (silentSignInInProgress) {
		return;
	}

	silentSignInInProgress = true;
	let response;

	try {
		response = await retryOnErrorAsync(() => oidcProvider.signinSilent({
			scope: oidcConfig.scope,
			response_type: oidcConfig.response_type
		}), {retryCount: 5, retryTimeMillis: 500, jobName: 'signinSilent'});
	} catch (e) {
		console.log("Caught an error on silent sign-in", e)
		oidcProvider.getUser().then(userData => {
			localStorage.setItem(KEY_AUTH_TOKEN, userData.access_token);
			user.token = userData.access_token;
		});

		return;
	} finally {
		silentSignInInProgress = false;
	}

	localStorage.setItem(KEY_AUTH_TOKEN, response.access_token);
	user.token = response.access_token;
}

const getUserAsync = async forceToRemote => {
	if (typeof user.id !== 'undefined' && !forceToRemote) {
		return {...user};
	}

	if (!user.token) {
		return {};
	}

	const oidcUser = await oidcProvider.getUser();

	if (!oidcUser) {
		// test case: tab 1 - user logging in. tab 2 - user auto logged in.
		// tab 1 - user sign out. tab 2 - user has to be signed out in 2 sec
		// vise-versa could work well by the well.

		console.log(getConsoleTime(), 'SILENT SIGN IN');

		try {
			await oidcProvider.signinSilent();
		} catch (e) {
			console.error('Failed to make silent sign-in. Do log-in', e);
			return logIn();
		}
	}

	let response;
	try {
		response = await tryGetRemoteUserAsync();
	} catch (e) {
		console.error("Failed to get remote user", e);
		return {};
	}

	if (response && response.body) {
		setUser(response.body);
	} else {
		console.error('No response body')
		await logOut();
		return {};
	}

	return {...user};
};

async function tryGetRemoteUserAsync() {
	return await retryOnErrorAsync(async (cancel) => {
		try {
			return await queryUserInfo();
		} catch (queryUserInfoError) {
			if (queryUserInfoError.status !== 401) {
				throw queryUserInfoError;
			}

			try {
				await doSilentSignin()
				return await queryUserInfo();
			} catch (err) {
				console.log(getConsoleTime(), 'getUserAsync, user.token, oidcUser, USER_INFO_URL 401', err);
				await logOut();
				cancel();
				throw err;
			}
		}
	}, {retryCount: 5, retryTimeMillis: 500, jobName: 'queryUserInfo'});
}

const logInCallback = async () => {
	try {
		const userFromOidc = await oidcProvider.signinRedirectCallback();

		localStorage.setItem(KEY_AUTH_TOKEN, userFromOidc.access_token);
		localStorage.setItem(KEY_SKIP_LANDING, true);

		user.token = userFromOidc.access_token;
		user.skipLanding = true;

		await getUserAsync(true);

		return true;
	} catch (e) {
		// eslint-disable-next-line
		console.error(getConsoleTime(), 'LOGIN_CALLBACK', e);
		await logOut();
	}

	return false;
};

const signinSilentCallback = async () => {
	try {
		await oidcProvider.signinSilentCallback();

		const {access_token: accessToken} = await oidcProvider.getUser();

		localStorage.setItem(KEY_AUTH_TOKEN, accessToken);
		user.token = accessToken;

		return true;
	} catch (e) {
		// eslint-disable-next-line
		console.error(getConsoleTime(), 'SILENT_LOGIN_CALLBACK', e);
		await logOut();
	}

	return false;
};

const getUser = () => ({...user});

oidcProvider.events.addAccessTokenExpired(async () => {
	console.log(getConsoleTime(), 'TOKEN expired...');

	await doSilentSignin();
});
oidcProvider.events.addAccessTokenExpiring(async () => {
	const oidcUser = await oidcProvider.getUser() || {};
	console.log(getConsoleTime(), 'TOKEN expiring in', oidcUser['expires_in'] || 'unknown');

	await doSilentSignin();
});

oidcProvider.events.addUserSignedOut(logOut);

const authClient = {
	getUser,
	getUserAsync,
	logIn,
	logInCallback,
	signinSilentCallback,
	logOut,
	getStartingData: () => ({
		isAuthorized: user.token !== null,
		skipLanding: user.skipLanding
	})
};

export default authClient;
