import {
	GET_AGENT_REPORT
} from '../actions/reports';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_AGENT_REPORT:
			return {
				...state,
				...action.data.content
			};
		default:
			return state;
	}
};
