import {AUTH_LOGIN, AUTH_LOGOUT, SET_AUTH_DATA} from '../actions/auth';
import authClient from '../../utils/auth-client';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_AUTH_DATA:
			return {
				...state,
				...action.data
			};
		case AUTH_LOGIN: {
			// eslint-disable-next-line
			console.error('AUTH_LOGIN is depricated');
			return state;
		}
		case AUTH_LOGOUT:
			authClient.logOut();
			return state;
		default:
			return state;
	}
};
