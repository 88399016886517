import React from 'react';
import {Switch, Route} from 'react-router';
import WithAuth from './with-auth';
import asyncComponent from '../utils/async-component';

const Layout = asyncComponent(() => import('../components/client/layout')
	.then(module => module.default)
);

const Homepage = asyncComponent(() => import('../components/client/pages/homepage')
	.then(module => module.default)
);

const SalesPage = asyncComponent(() => import('../components/client/pages/sales')
	.then(module => module.default)
);

const AddNewSalePage = asyncComponent(() => import('../components/client/pages/add-new-sale')
	.then(module => module.default)
);

const EditSaleDetailsPage = asyncComponent(() => import('../components/client/pages/edit-sale-details')
	.then(module => module.default)
);

const SaleDetailsPage = asyncComponent(() => import('../components/client/pages/sale-details')
	.then(module => module.default)
);

const AllSalesPage = asyncComponent(() => import('../components/client/pages/all-sales')
	.then(module => module.default)
);

const InvoicesPage = asyncComponent(() => import('../components/client/pages/invoices')
	.then(module => module.default)
);

const NotaryInvoiceDetailsPage = asyncComponent(() => import('../components/client/pages/notary-invoice-details')
	.then(module => module.default)
);

const ProformaInvoiceDetailsPage = asyncComponent(() => import('../components/client/pages/proforma-invoice-details')
	.then(module => module.default)
);

const SextantInvoiceDetailsPage = asyncComponent(() => import('../components/client/pages/sextant-invoice-details')
	.then(module => module.default)
);

const SalesChartPage = asyncComponent(() => import('../components/client/pages/sales-chart')
	.then(module => module.default)
);

const NotFound = asyncComponent(() => import('../components/common/not-found')
	.then(module => module.default)
);

const AccessDenied = asyncComponent(() => import('../components/common/access-denied')
	.then(module => module.default)
);

const AddGodson = asyncComponent(() => import('../components/client/pages/add-godson')
	.then(module => module.default)
);

const EventFeed = asyncComponent(() => import('../components/client/pages/event-feed')
	.then(module => module.default)
);

const ChallengeSalesPage = asyncComponent(() => import('../components/client/pages/challenge-sales')
	.then(module => module.default)
);

const ChallengeMandatesPage = asyncComponent(() => import('../components/client/pages/challenge-mandates')
	.then(module => module.default)
);

// const ChallengeRecruitmentPage = asyncComponent(() => import('../components/client/pages/challenge-recruitment')
// 	.then(module => module.default)
// );

const ChallengePage = asyncComponent(() => import('../components/client/pages/challenges')
	.then(module => module.default)
);

const SupportPage = asyncComponent(() => import('../components/common/support')
	.then(module => module.default)
);

const FAQPage = asyncComponent(() => import('../components/common/faqs')
	.then(module => module.default)
);

const ClientRoutes = () => (
	<WithAuth roleCheck={roles => roles.includes('agent')}>
		<Layout>
			<Switch>
				<Route exact path="/app/" component={Homepage}/>
				<Route exact path="/app/sales" component={SalesPage}/>
				<Route exact path="/app/sales/add-sale" component={AddNewSalePage}/>
				<Route exact path="/app/sales/:group/:status" component={AllSalesPage}/>
				<Route exact path="/app/sales/:group/:status/chart" component={SalesChartPage}/>
				<Route exact path="/app/sales/:group/:status/:id" component={SaleDetailsPage}/>
				<Route exact path="/app/sales/:group/:status/:id/edit" component={EditSaleDetailsPage}/>
				<Route exact path="/app/invoices/:type?" component={InvoicesPage}/>
				<Route exact path="/app/invoices/proforma/:id" component={ProformaInvoiceDetailsPage}/>
				<Route exact path="/app/invoices/notary/:id" component={NotaryInvoiceDetailsPage}/>
				<Route exact path="/app/invoices/sextant/:id" component={SextantInvoiceDetailsPage}/>
				<Route exact path="/app/challenges/sales" component={ChallengeSalesPage}/>
				<Route exact path="/app/challenges/mandates" component={ChallengeMandatesPage}/>
				{/* <Route exact path="/app/challenges/recruitment" component={ChallengeRecruitmentPage}/> */}
				<Route exact path="/app/challenges/" component={ChallengePage}/>
				<Route exact path="/app/feed" component={EventFeed}/>
				<Route exact path="/app/add-new-mentee" component={AddGodson}/>
				<Route exact path="/app/support" component={SupportPage}/>
				<Route exact path="/app/403" component={AccessDenied}/>
				<Route exact path="/app/faq" component={FAQPage}/>
				<Route exact path="/app/*" component={NotFound}/>
			</Switch>
		</Layout>
	</WithAuth>
);

export default ClientRoutes;
