import {showNotification} from '../actions/notifications';
import authClient from '../../utils/auth-client';
import {selectEndpoint} from '../../api/endpoint';
import makeRequest from '../../utils/http/make-request';

export default store => next => async action => {
	const {http} = action;

	if (!http) {
		return next(action);
	}

	if (!http.request) {
		throw new Error('http request should be provided');
	}

	const request = {...http.request};
	const state = store.getState();

	request.url = selectEndpoint(request.url, state.auth.roles);
	if (http.withToken) {
		request.token = state.auth.token;
	}

	try {
		action.http.response = await makeRequest(request);
		return next(action);
	} catch (error) {
		if (error.status === 500) {
			next(showNotification(error.response.statusText, 'error'));

			throw new Error(error.response.statusText);
		}

		if (error.status === 400) {
			next(showNotification(
				error.response.body.errors.map(item => `${item.name}: ${item.message}`),
				'error'
			));

			throw new Error(error.response.body);
		}

		if (error.status === 401) {
			authClient.logOut();
			throw new Error(error.response.body);
		}

		if (error.message) {
			console.error(`${error.message} ${error.stack}`); // eslint-disable-line
		}

		throw error;
	}
};
