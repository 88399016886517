export default function (resourceType) {
	return (state, action) => {
		if (action.resourceType !== resourceType) {
			return state;
		}

		if (action.listMeta) {
			const listMeta = {...state.listMeta, [action.list]: action.listMeta};

			return {...state, listMeta};
		}

		return state;
	};
}
