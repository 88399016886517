type GlobalTokenStorage = typeof window & {
	userInfo: {
		token: string,
	}
};

export function getAccessToken() {
	const globalTokenStorage = window as GlobalTokenStorage;

	return globalTokenStorage.userInfo.token;
}
