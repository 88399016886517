import React from 'react';
import userManager from '../../../utils/auth-client';

class SignInSilentCallback extends React.Component {
	async componentDidMount() {
		userManager.signinSilentCallback();
	}

	render() {
		// console.log('SILENT SIGNIN RENDER');
		return null;
	}
}

export default SignInSilentCallback;
