const sendProforma = id => `/invoice/proforma/${id}/send`;
const sendNotary = id => `/invoice/notary/${id}/send`;
const sendSextant = id => `/invoice/sextant/${id}/send`;
const downloadProforma = id => `/sale/${id}/invoice/proforma`;
const downloadNotary = id => `/sale/${id}/notary/proforma`;
const updateInvoiceDate = id => `/invoice/${id}/set-payment-date`;
const readProformaDetails = id => `/invoice/proforma/${id}/details`;
const readNotaryDetails = id => `/invoice/notary/${id}/details`;
const readSextantDetails = id => `/invoice/sextant/${id}`;
const updateSextantPaidStatus = id => `/invoice/sextant/${id}/set-paid`;
const updateNotaryPaidStatus = id => `/invoice/notary/${id}/set-paid`;
const readAdminMenteeInvoices = () => '/invoice/godsons';
const updateMenteePaidStatus = () => '/invoice/godsons/paid';
const updateMenteePaidDate = () => '/invoice/godsons/payment-date';
const searchAutocomplete = () => '/invoice/search-autocomplete';
const readSextantInvoices = () => '/invoice/sextant';

export default ({
	sendProforma,
	sendNotary,
	sendSextant,
	downloadProforma,
	downloadNotary,
	updateInvoiceDate,
	readProformaDetails,
	readNotaryDetails,
	readSextantDetails,
	updateSextantPaidStatus,
	updateNotaryPaidStatus,
	readAdminMenteeInvoices,
	updateMenteePaidDate,
	updateMenteePaidStatus,
	searchAutocomplete,
	readSextantInvoices
});
