import React from 'react';
import {compose} from 'recompose';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import {setAuthData} from '../../../redux/actions/auth';
import authClient from '../../../utils/auth-client';

function mapDispatchToProps(dispatch) {
	return {
		 processLogin: data => dispatch(setAuthData(data))
	};
}

class SigninCallback extends React.Component {
	async componentDidMount() {
		this._isMounted = true;
		const {history, processLogin} = this.props;
		const success = await authClient.logInCallback();

		if (success) {
			const user = authClient.getUser();

			await processLogin(user);
			history.push('/');
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return '';
	}
}

const enhance = compose(
	withRouter,
	connect(null, mapDispatchToProps)
);

export default enhance(SigninCallback);
