import capitalize from '../utils/strings/capitalize';
import capitalizeAll from '../utils/strings/capitalize-all';

export default (value, format) => {
	if (format === 'capitalize') {
		return capitalize(value);
	}
	if (format === 'capitalizeAll') {
		return capitalizeAll(value);
	}

	return value;
};
