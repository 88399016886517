import {TracfinFields, TracfinBuyerType} from '../../domain/tracfin';

export default ({
  "commission-progress-bar": {
    "pack-gold-title": "Maximise your sales to try to get the <1><0>PACK GOLD</0></1> which is then free for 3 months",
    "tracking-disabled": "Your commission progress tracking will start in the <1>!#!month!#!</1>."
  },
  "sales-admin": {
    "export-to-excel": "Export to excel"
  },
  "sales-page": {
    "status-filter": {
      "button": "Filter"
    }
  },
  "sale-form": {
    "regular": "Myself only",
    "sharedInHouse": "Myself with another Sextant agent",
    "sharedWithAgency": "Sale with other agency",
    "fields": {
      "agent": "Agent",
      "mandateNumber": "Mandate",
      "price": "Price FAI",
      "commission": "Commission, TTC",
      "commissionPayer": "Commission payed by",
      "seller": "Seller(s): First and last name(s)",
      "sellerEmail": "Seller(s): Email",
      "buyer": "Buyer(s): First and last name(s)",
      "buyerEmail": "Buyer(s): Email",
      "sellerDetails": "Seller's details",
      "sellerCountry": "Seller's country",
      "sellerAddress": "Seller's address",
      "sellerPostcode": "Seller's postcode",
      "sellerTown": "Seller's town",
      "buyerDetails": "Buyer's details",
      "buyerCountry": "Buyer's country",
      "buyerAddress": "Buyer's address",
      "buyerPostcode": "Buyer's postcode",
      "buyerTown": "Buyer's town",
      "notary": "Notary",
      "propertyType": "Type of property",
      "propertyPostalCode": "Postal code of property",
      "town": "Town",
      "mandateDate": "Date of the mandate",
      "address": "Address of property"
    },
    "noOptions": "No options",
    "placeholders": {
      "coOwner": "Type to find agent *",
      "coOwnerAgency": "Competitor agency *"
    },
    "hints": {
      "rate": "You will get <1>!#!ownerRate!#!%</1> of commission, <3>!#!coOwnerName!#!</3> will get <5>!#!coOwnerRate!#!</5>%",
      "coOwnerPlaceholder": "another agent",
      "coOwnerAgencyPlaceholder": "competitor agency",
      "startBoostActivated": "Congratulations! 95% retrocession on this sale!"
    },
    "values": {
      "seller": "Seller",
      "buyer": "Buyer"
    },
    "submitPopup": {
      "title": "There is already sale with same mandate number",
      "content": "Are you sure you want to update sale with duplicate mandate number?",
      "try-again": "We are sorry, but something went wrong. Please try again later or contact support",
      "ok": "Ok"
    }
  },
  "agent-network-admin": {
    "header": "Agent's network"
  },
  "sale-details": {
    "mySale": "My sale",
    "sale": "Sale",
    "status": "status",
    "byAgent": "by agent",
    "sharingInfo": "<0><0>!#!rate!#!%</0></0> of commission is shared with <2><0>!#!sharedWith!#!</0></2>",
    "myCommission": "My commission",
    "invoiceButton": "invoice",
    "cancelButton": "Cancel sale",
    "successMessage": {
      "message": "Sale has been updated",
      "ok": "OK"
    },
    "cancellation-confirmation-dialog": {
      "title": "Cancel the sale?",
      "content": "Are you sure cancel this sale? Only Admin can revert this change",
      "back": "No",
      "confirm": "Yes"
    },
    "delete-confirmation-dialog": {
      "title": "Delete the sale",
      "content": "Are you sure delete this sale?",
      "success": "Sale has been successfully deleted",
      "back": "No",
      "confirm": "Yes",
      "ok": "Ok",
      "deleteSaleButton": "Delete sale",
      "try-again": "We are sorry, but something went wrong. Please try again later or contact support"
    },
    "startBoostRate": "Welcome bonus sale: !#!rate!#!% commission.",
    "startBoostEndDate": "If sale is closed before !#!date!#!",
    "fields": {
      "mandateNumber": "Mandate",
      "mandateDate": "Date of the mandate",
      "price": "Price FAI",
      "commission": "Commission TTC",
      "commissionPayer": "Commission payed by",
      "seller": "Seller",
      "buyer": "Buyer",
      "notary": "Notary",
      "propertyType": "Type of property",
      "propertyAddress": "Address of property",
      "propertyPostalCode": "Postal code of property",
      "town": "Town"
    },
    "processingFields": {
      "status": "Status",
      "paymentType": {
        "empty": "Please select...",
        "title": "Payment type",
        "mortgage": "100% bank loan",
        "cash": "100% cash",
        "creditAndDeposit": "Mixed loan + contribution"
      },
      "sourceOfFunds": {
        "empty": "Please select...",
        "title": "Source of funds",
        "saving": "Saving",
        "lifeInsurance": "Life insurance",
        "capitalGains": "Capital gains",
        "disposalEstate": "Disposal estate",
        "donation": "Donation",
        "heritage": "Heritage",
        "gambling": "Gambling",
        "dividends": "Dividends",
        "others": "Others"
      },
      "paymentDate": "Payment date",
      "compromis": "Signature compromis",
      "compromisDate": {
        "title": "Compromis",
        "field": "Compromis date"
      },
      "compromisDocument": {
        "title": "Compromis document",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)",
        "infoModal": {
          "title": "Attention",
          "text": "Please do not forget to fill the LCB-FT Tracfin",
          "button": "Ok"
        }
      },
      "commissionDistribution": {
        "title": "% commission distribution (shared sales between agents)",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)"
      },
      "deedOfSale": "Signature deed of sale",
      "deedOfSaleDate": {
        "title": "Deed of sale",
        "field": "Deed of sale date"
      },
      "proformaInvoice": {
        "title": "Proforma invoice",
        "create": "Create",
        "created": "Created",
        "createdDate": "Date of creation",
        "prerequisitesErrors": "Following fields should have valid data to generate invoice:",
        "invoiceForbiddedOffer": "Proforma invoice cannot be created for offer sale",
        "invoiceForbiddedCancelled": "Proforma invoice cannot be created for cancelled sale",
        "invoiceAlreadyGeneratedOpenToChanges": "Any change to sale will recreate proforma invoice",
        "invoiceAlreadyPaid": "Invoice is paid",
        "invoiceAlreadyGenerated": "Proforma invoice is generated",
        "invoiceCanBeGenerated": "Proforma invoice can be generated",
        "invoiceCanBeGeneratedOn": "Proforma invoice will be created after submit",
        "invoiceIsClosedToModifications": "Proforma invoice is closed to modifications",
        "invoiceNotCompromis": "Proforma invoice can be created only for compromis sale",
        "prerequisitesApproved": "You have nothing to do, please wait until admin will verify the sale and create an invoice."
      },
      "notaryInvoice": {
        "title": "Notary invoice",
        "create": "Create",
        "paymentDate": "Payment date",
        "paid": "Paid ?",
        "prerequisitesErrors": "Following fields should have valid data to create notary invoice:",
        "helperText": {
          "invoiceNotSold": "Notary invoice can be created only for sold sale",
          "invoiceCanBeGenerated": "Notary invoice can be created",
          "invoiceEditable": "Changed payment date will lead to notary invoice recreation",
          "invoiceIsClosedToModifications": "Unmodifiable"
        }
      },
      "certificationDocument": {
        "title": "Sale certification document",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)"
      },
      "agentInvoice": {
        "title": "Agent invoice",
        "upload": "$t(controls:upload-form.upload)",
        "cancelUpload": "$t(controls:upload-form.cancel-upload)",
        "deleteFile": "$t(controls:upload-form.delete-file)",
        "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
        "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
        "previewNothing": "$t(controls:upload-form.preview-nothing)"
      },
      "sruDelayPurged": "SRU Delay Purged",
      "backedByBritishTeam": "Sale from Sextant English team",
      "approved": "Approved?",
      "signed": "Signed?",
      "rejectReason": "Reject reason",
      "noFile": "No file",
      "notVerified": "Not verified",
      [TracfinFields.SELLER]: "LCB-FT Tracfin Seller(s)",
      [TracfinFields.BUYER]: {
        "title": "LCB-FT Tracfin Buyer(s)",
        "text": "Identity(ies) buyer(s)\n& Questionnaires",
        [TracfinFields.BUYER_COMPANY_KEY]: {
          "title": "Buyer - Company : !#!name!#!" 
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_KEY]: {
          "title": "Beneficiary !#!count!#! - Company",
        },
        [TracfinFields.BUYER_INDIVIDUAL_KEY]: {
          "title": "Buyer !#!count!#! - Individual : !#!name!#!",
        },
        [TracfinFields.MYNOTARY_DOCUMENT]: {
          "question": "Has LCB-FT-Tracfin been\ncompleted in MyNotary?",
          "title": "LCB-FT Tracfin form - MyNotary\nPermanent & Reinforced Vigilance",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_KBIS]: {
          "title": "KBIS",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_STATUTES]: {
          "title": "Statutes",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_OWNER]: {
          "title": "Beneficial owner(s) - INPI document",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_PASSPORT]: {
          "title": "Identity document(s) or passport(s) - Beneficial owner(s)",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_COMPANY_BENEFICIARY_ADDRESS]: {
          "title": "Others (Proof of address, powers of the legal representative, etc.)",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_INDIVIDUAL_PASSPORT]: {
          "title": "ID / Passport",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_INDIVIDUAL_PASSPORT_ADDITIONAL]: {
          "title": "ID / Passport second page",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.BUYER_INDIVIDUAL_PROOF_OF_ADDRESS]: {
          "title": "Others (Proof of address...)",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)"
        },
        [TracfinFields.VIGILANCE]: {
          "title": "LCB-FT Tracfin\nVigilance permanente for buyers",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)",
          "download": "$t(controls:upload-form.download)"
        },
        [TracfinFields.ENHANCED_VIGILANCE]: {
          "title": "LCB-FT Tracfin\nReinforced Vigilance for buyers",
          "upload": "$t(controls:upload-form.upload)",
          "cancelUpload": "$t(controls:upload-form.cancel-upload)",
          "deleteFile": "$t(controls:upload-form.delete-file)",
          "previewToUpload": "$t(controls:upload-form.preview-to-upload)",
          "previewUploaded": "$t(controls:upload-form.preview-uploaded)",
          "previewNothing": "$t(controls:upload-form.preview-nothing)",
          "download": "$t(controls:upload-form.download)"
        },
        [TracfinFields.ENHANCED_VIGILANCE_REQUIRED]: {
          "text": "Does this sale need Enhanced Vigilance?",
          "button": "Enable Enhanced Vigilance"
        },
        "add-new-modal": {
          "title": "Add a new buyer",
          "text": {
            [TracfinBuyerType.INDIVIDUAL]: "Person: First name & Last name",
            [TracfinBuyerType.COMPANY]: "Company: Designation",
            [TracfinBuyerType.EMPTY]: ""
          },
          "confirm": "Add"
        },
        "add-person": "ADD BUYER +",
        "add-beneficiary": "ADD BENEFICIARY +",
        "individual-or-company": {
          "text": "Define if buyer individual\nor it is a company",
          [TracfinBuyerType.INDIVIDUAL]: "INDIVIDUAL",
          [TracfinBuyerType.COMPANY]: "COMPANY"
        },
        "info-links": {
          "gafi": "GAFI WEBSITE",
          "assets-freeze": "LIST OF ASSETS FREEZE",
          "inpi": "INPI WEBSITE",
        },
        "reset": {
          "title": "Reset buyer section",
          "text": "Are you sure you want to reset the buyer section?\n(Uploaded files will be lost!)"
        },
        "remove": {
          "title": "Remove buyer",
          "text": "Are you sure you want to remove !#!name!#!?\n(Uploaded files will be lost!)"
        },
        "none": {
          "title": "",
          "text": ""
        },
        "approve": {
          "title": "Approve Tracfin buyer section",
          "text": "Are you sure you want to approve the Tracfin buyer section?\n(All rejections and messages will be lost!)"
        }
      },
      "uploadInfoModal": {
        "title": "Allowed file extensions",
        "text": ".jpg, .png, .pdf and .zip extensions are supported.\nPlease use .pdf or .zip if the multiple page attachment needed",
        "confirm": "Don't show again"
      },
      "filePreview": {
        "fileIsNotSupported": "The extension \"!#!ext!#!\" is not supported. Please download to preview.",
        "zip": "This is a .zip file. Please download to preview."
      }
    },
    "values": {
      "seller": "Seller",
      "buyer": "Buyer",
      "yes": "Yes",
      "no": "No",
      "generateInvoice": "Generate invoice"
    },
    "compromis": {
      "compromisDocumentTitle": "Compromis document",
      "previewCompromis": "Preview document",
      "previewCompromisDisabled": "No document uploaded",
      "dropzoneLabel": "Add document",
      "download": "Download"
    },
    "submitMessage": {
      "success": "Sale details are updated"
    }
  },
  "owner-level": {
    "text": "Level"
  },
  "add-new-godson": {
    "title": "Add new mentee",
    "descriptionOne": "You want to extend your network ?",
    "descriptionTwo": "Follow those few steps and we will study your potential Mentee’s application",
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "phone": "Phone",
    "selectsLabel": {
      "commercial": "Does he/she has commercial experience?",
      "estate": "Does he/she has real estate experience?",
      "yes": "Yes",
      "no": "No"
    },
    "buttons": {
      "next": "Next",
      "back": "Back",
      "send": "Send it"
    },
    "errorMessage": {
      "message": "Error!",
      "description": "Something went wrong"
    },
    "successMessage": "<0>Congratulations!</0><1/>You application for<3/><4/><5><0>!#!name!#!</0></5><6/>has been sent. We will get in touch shortly."
  },
  "sales-type": {
    "all": "All",
    "my": "My sales",
    "mentees": "Mentee's sales"
  },
  "invoices-type": {
    "proforma": "Proforma",
    "notary": "Notary",
    "sextant": "Sextant",
    "mentee": "Mentee"
  },
  "invoices-paid-notary": {
    "both": "All",
    "yes": "Paid",
    "no": "Not paid"
  },
  "invoices-paid-sextant": {
    "both": "All",
    "yes": "Paid",
    "no": "Not paid"
  },
  "send-invoice": {
    "title": "Send invoice to email",
    "send": "Send",
    "cancel": "Cancel",
    "great": "Great",
    "success": "<0><0>The invoice </0></0><1/><2><0>!#!invoiceNumber!#!</0></2><3/>has been sent to email:<5/><6><0>!#!email!#!</0></6>",
    "notPaidNotary": "Sorry but you cannot send your invoice until sale is not paid by notary"
  },
  "event-feed": {
    "details": "Details",
    "like": "Like",
    "delete": "Delete",
    "comment": "Comment",
    "addEvent": "Launch new event",
    "addComment": "Leave a comment"
  },
  "sales-summary": {
    "total": "Total",
    "totalCountSingle": "<0>!#!totalCount!#!</0> sale",
    "totalCountMultiple": "<0>!#!totalCount!#!</0> sales"
  },
  "family-tree": {
    "add-button-tooltips": {
      "n3-expand": "To add 3 agents on your first level, one of your godsons needs to add one more godson"
    }
  },
  "detach-agent": {
    "tooltip": "Detach this agent",
    "title": "Detach !#!agentName!#! from !#!parentName!#!",
    "text": "You are going to detach this agent from the mentor. This action cannot be undone.",
    "detach": "Detach this agent from mentor"
  }
});