import PropTypes from 'prop-types';

const ALL_SALES = 'all';
const MY_SALES = 'my';
const MENTEE_SALES = 'mentees';
const asArray = [ALL_SALES, MY_SALES, MENTEE_SALES];

export default ({
	ALL_SALES,
	MY_SALES,
	MENTEE_SALES,
	asArray,
	propType: PropTypes.oneOf(asArray)
});
