import {actionTypes} from 'redux-resource';
import {HttpMethods} from '../../../domain/http';
import {InvoicesUrlBuilder} from '../../../api/url-builder';

export const SEND_PROFORMA_INVOICE = 'SEND_PROFORMA_INVOICE';
export const SEND_NOTARY_INVOICE = 'SEND_NOTARY_INVOICE';
export const SEND_SEXTANT_INVOICE = 'SEND_SEXTANT_INVOICE';
export const UPDATE_INVOICE_PAID_DATE = 'UPDATE_INVOICE_PAID_DATE';
export const READ_PROFORMA_DETAILS = 'READ_PROFORMA_DETAILS';
export const READ_NOTARY_DETAILS = 'READ_NOTARY_DETAILS';
export const READ_SEXTANT_DETAILS = 'READ_SEXTANT_DETAILS';
export const UPDATE_SEXTANT_PAID_STATUS = 'UPDATE_SEXTANT_PAID_STATUS';
export const UPDATE_NOTARY_PAID_STATUS = 'UPDATE_NOTARY_PAID_STATUS';
export const UPDATE_MENTEE_PAID_STATUS = 'UPDATE_MENTEE_PAID_STATUS';
export const UPDATE_MENTEE_PAID_DATE = 'UPDATE_MENTEE_PAID_DATE';
export const READ_ADMIN_MENTEE_DETAILS = 'READ_ADMIN_MENTEE_DETAILS';
export const SEARCH_AUTOCOMPLETE = 'SEARCH_AUTOCOMPLETE';

const proformaResourceType = 'proformaInvoices';
const notaryResourceType = 'notaryInvoices';
const sextantResourceType = 'sextantInvoices';
const menteeResourceType = 'menteeInvoices';

export const readProformaInvoices = ({
	pageNumber, pageSize, orderBy, orderDirection, paid
}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: proformaResourceType,
	request: 'read',
	list: 'all',
	url: 'invoice/proforma',
	requestDetails: {
		paged: !!pageSize
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		paid
	},
	postTransform: items => items.map(item => ({
		...item,
		sellerBuyer: `${item.seller} / ${item.buyer}`,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const readNotaryInvoices = ({
	pageNumber, pageSize, orderBy, orderDirection, paid
}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: notaryResourceType,
	request: 'read',
	list: 'all',
	url: 'invoice/notary',
	requestDetails: {
		paged: !!pageSize
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		paid
	},
	postTransform: items => items.map(item => ({
		...item,
		sellerBuyer: `${item.seller} / ${item.buyer}`,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const readAdminProformaInvoices = ({
	pageNumber, pageSize, orderBy, orderDirection, paid
}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: proformaResourceType,
	request: 'read',
	list: 'all',
	url: 'admin/invoice/proforma',
	requestDetails: {
		paged: !!pageSize,
		ordered: !!orderBy
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		paid
	},
	postTransform: items => items.map(item => ({
		...item,
		sellerBuyer: `${item.seller} / ${item.buyer}`,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const readAdminNotaryInvoices = ({
	pageNumber, pageSize, orderBy, orderDirection, paid
}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: notaryResourceType,
	request: 'read',
	list: 'all',
	url: 'admin/invoice/notary',
	requestDetails: {
		paged: !!pageSize,
		ordered: !!orderBy
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		paid
	},
	postTransform: items => items.map(item => ({
		...item,
		sellerBuyer: `${item.seller} / ${item.buyer}`,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const readSextantInvoices = ({
	pageNumber, pageSize, orderBy, orderDirection, paid, mandateNumbers
}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: sextantResourceType,
	request: 'read',
	list: 'all',
	url: 'invoice/sextant',
	requestDetails: {
		paged: !!pageSize
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		paid,
		mandateNumbers
	},
	postTransform: items => items.map(item => ({
		...item,
		sellerBuyer: `${item.seller} / ${item.buyer}`,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const readAdminSextantInvoices = ({
	pageNumber, pageSize, orderBy, orderDirection, paid, searchString, mandateNumbers, agentIds
}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: sextantResourceType,
	request: 'read',
	list: 'all',
	url: 'admin/invoice/sextant',
	requestDetails: {
		paged: !!pageSize
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		paid,
		searchString,
		mandateNumbers,
		agentIds
	},
	postTransform: items => items.map(item => ({
		...item,
		sellerBuyer: `${item.seller} / ${item.buyer}`,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const readMenteeInvoices = timestamp => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: menteeResourceType,
	request: 'read',
	list: 'all',
	url: 'invoice/godsons',
	data: {timestamp: timestamp.toISOString()}
});

export const readSextantInvoice = id => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: sextantResourceType,
	request: 'readDetails',
	list: 'details',
	url: 'invoice/sextant',
	data: {id},
	postTransform: item => ({
		...item,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	})
});

export const readAdminSextantInvoice = id => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType: sextantResourceType,
	request: 'readDetails',
	list: 'details',
	url: 'admin/invoice/sextant',
	data: {id},
	postTransform: item => ({
		...item,
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	})
});

export const sendProformaInvoice = (id, email) => ({
	type: SEND_PROFORMA_INVOICE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.sendProforma(id),
			data: {
				email
			}
		}
	}
});

export const sendNotaryInvoice = (id, email) => ({
	type: SEND_NOTARY_INVOICE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.sendNotary(id),
			data: {
				email
			}
		}
	}
});

export const sendSextantInvoice = (id, email) => ({
	type: SEND_SEXTANT_INVOICE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.sendSextant(id),
			data: {
				email
			}
		}
	}
});

export const updateInvoicePaidDate = (id, paymentDate, type) => ({
	type: UPDATE_INVOICE_PAID_DATE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.updateInvoiceDate(id),
			data: {
				paymentDate,
				type
			}
		}
	}
});

export const readProformaDetails = id => ({
	type: READ_PROFORMA_DETAILS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: InvoicesUrlBuilder.readProformaDetails(id)
		}
	}
});

export const readNotaryDetails = id => ({
	type: READ_NOTARY_DETAILS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: InvoicesUrlBuilder.readNotaryDetails(id)
		}
	}
});

export const readAdminNotaryInvoice = id => ({
	type: READ_NOTARY_DETAILS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: InvoicesUrlBuilder.readNotaryDetails(id)
		}
	}
});

export const updateSextantPaidStatus = (id, paid) => ({
	type: UPDATE_SEXTANT_PAID_STATUS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.updateSextantPaidStatus(id),
			data: {
				paid
			}
		}
	}
});

export const updateNotaryPaidStatus = (id, paid) => ({
	type: UPDATE_NOTARY_PAID_STATUS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.updateNotaryPaidStatus(id),
			data: {
				paid
			}
		}
	}
});

export const readAdminMenteeInvoices = ({timestamp, targetId}) => ({
	type: READ_ADMIN_MENTEE_DETAILS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: InvoicesUrlBuilder.readAdminMenteeInvoices(),
			data: {
				timestamp,
				targetId
			}
		}
	}
});

export const updateMenteePaidStatus = params => ({
	type: UPDATE_MENTEE_PAID_STATUS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.updateMenteePaidStatus(),
			data: params
		}
	}
});

export const updateMenteePaidDate = params => ({
	type: UPDATE_MENTEE_PAID_DATE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			url: InvoicesUrlBuilder.updateMenteePaidDate(),
			data: params
		}
	}
});

export const searchAutocomplete = params => ({
	type: SEARCH_AUTOCOMPLETE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: InvoicesUrlBuilder.searchAutocomplete(),
			data: params
		}
	}
});
