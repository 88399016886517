export type RetryOptions = Readonly<{
	retryCount: number,
	retryTimeMillis: number,
	jobName: string,
}>;

export interface Job<T> {
	(cancel: () => void): Promise<T>;
}

function sleep(millis: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, millis));
}

/**
 * Execute job several times on an each caught error.
 * If delay was cancelled return undefined (no cancellation support yet)
 * @param job to execute
 * @param options retry options
 */
export async function retryOnErrorAsync<T>(job: Job<T>, options: RetryOptions): Promise<T | undefined> {
	async function retry(attempt: number): Promise<T | undefined> {
		let cancelled = false;

		await sleep(options.retryTimeMillis);

		try {
			return await job(() => cancelled = true);
		} catch (e) {
			if (cancelled) {
				console.log('retryOnErrorAsync: execution of', options.jobName,
					'is cancelled on attempt', attempt, 'out of', options.retryCount);
				return undefined;
			}

			const errorMessage = e.message ? e.message : e;
			if (attempt == options.retryCount) {
				console.log('retryOnErrorAsync: failed all retries of ', options.jobName, 'error: ', errorMessage);
				throw e;
			}

			console.log('retryOnErrorAsync: caught an error in ', options.jobName,
				'attempt', attempt, 'out of', options.retryCount, 'error:', errorMessage);
			return retry(attempt + 1);
		}
	}

	let cancel = false;
	try {
		return await job(() => cancel = true);
	} catch (e) {
		if (cancel) {
			console.log('retryOnErrorAsync: job', options.jobName, 'cancelled before any retry attempts');
			return undefined;
		}

		const errorMessage = e.message ? e.message : e;
		console.log('retryOnErrorAsync: start retry of job', options.jobName, 'initial error: ', errorMessage);
		return retry(1);
	}
}
