import React from 'react';
import PropTypes from 'prop-types';
import Notification from '../notification';

const Layout = ({children}) => (
	<React.Fragment>
		{children}
		<Notification/>
	</React.Fragment>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
