import {actionTypes} from 'redux-resource';
import SaleTypes from '../../domain/sale/sale-types';
import {SalesUrlBuilder} from '../../api/url-builder';
import {HttpMethods} from '../../domain/http';
import {SaleGroups} from '../../domain/sale/index';

const resourceType = 'sale';

function saleDetailsPostTransform({
	coOwnerId, coOwner, mandateDate, lastModificationDate, type, ...rest
}) {
	switch (type) {
		case SaleTypes.REGULAR:
			return ({
				...rest,
				type,
				coOwner: undefined,
				mandateDate: mandateDate && new Date(mandateDate),
				lastModificationDate: lastModificationDate && new Date(lastModificationDate)
			});
		case SaleTypes.SHARED_IN_HOUSE:
			return ({
				...rest,
				type,
				coOwner: coOwnerId && {id: coOwnerId, name: coOwner},
				mandateDate: mandateDate && new Date(mandateDate),
				lastModificationDate: lastModificationDate && new Date(lastModificationDate)
			});
		case SaleTypes.SHARED_WITH_AGENCY:
			return ({
				...rest,
				coOwnerAgency: coOwner, // TODO refactor & fix on backend
				type,
				mandateDate: mandateDate && new Date(mandateDate),
				lastModificationDate: lastModificationDate && new Date(lastModificationDate)
			});
		default: throw new Error(`${type} not implemented`);
	}
}

// TODO refactor to new API
export const readSale = (id, requestSuffix = '') => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'readDetails' + requestSuffix,
	list: 'details',
	urlPrefix: 'admin/',
	data: {id},
	postTransform: saleDetailsPostTransform
});

export const readSales = ({
	pageNumber, pageSize, orderBy, orderDirection, statusFilters, tracfinFilters, searchString
} = {}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'read',
	list: 'all',
	urlPrefix: 'admin/',
	requestDetails: {
		paged: !!pageSize,
		ordered: !!orderBy
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		statusFilters,
		tracfinBuyerStatusFilters: tracfinFilters,
		searchString
	},
	postTransform: items => items.map(item => ({
		...item,
		mandateDate: item.mandateDate && new Date(item.mandateDate),
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const updateSale = data => ({
	type: actionTypes.UPDATE_RESOURCES_PENDING,
	resourceType,
	request: 'update',
	urlPrefix: 'admin/',
	data,
	postTransform: saleDetailsPostTransform
});

export const changeSaleStatus = (id, status) => ({
	type: actionTypes.UPDATE_RESOURCES_PENDING,
	resourceType,
	request: 'changeStatus',
	urlPrefix: 'admin/',
	url: `sale/${id}/status`,
	data: {
		status
	}
});

export const addMySale = data => ({
	type: actionTypes.CREATE_RESOURCES_PENDING,
	resourceType,
	request: 'create',
	data
});

export const addSaleAdmin = data => ({
	type: actionTypes.CREATE_RESOURCES_PENDING,
	resourceType,
	urlPrefix: 'admin/',
	request: 'create',
	data
});

export const updateMySale = data => ({
	type: actionTypes.UPDATE_RESOURCES_PENDING,
	resourceType,
	request: 'update',
	data,
	postTransform: saleDetailsPostTransform
});

export const cancelMySale = id => ({
	type: actionTypes.UPDATE_RESOURCES_PENDING,
	resourceType,
	request: 'cancel',
	url: `sale/${id}/cancel`
});

export const readMySale = (id, requestSuffix = '') => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'readDetails' + requestSuffix,
	list: 'details',
	data: {id},
	postTransform: saleDetailsPostTransform
});

export const readMySales = ({
	pageNumber, pageSize, orderBy, orderDirection, statusFilter, requestType
} = {}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'read',
	list: 'all',
	requestDetails: {
		paged: !!pageSize,
		ordered: !!orderBy
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		statusFilter,
		requestType
	},
	postTransform: items => items.map(item => ({
		...item,
		mandateDate: item.mandateDate && new Date(item.mandateDate),
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		paymentDate: item.paymentDate && new Date(item.paymentDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const readMoreMySales = ({
	pageNumber, pageSize, orderBy, orderDirection, statusFilter, requestType
} = {}) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	mergeListIds: true,
	request: 'read',
	list: 'all',
	requestDetails: {
		paged: !!pageSize,
		ordered: !!orderBy
	},
	data: {
		pageSize,
		pageNumber,
		orderBy,
		orderDirection,
		statusFilter,
		requestType
	},
	postTransform: items => items.map(item => ({
		...item,
		mandateDate: item.mandateDate && new Date(item.mandateDate),
		deedOfSaleDate: item.deedOfSaleDate && new Date(item.deedOfSaleDate),
		paymentDate: item.paymentDate && new Date(item.paymentDate),
		compromisDate: item.compromisDate && new Date(item.compromisDate)
	}))
});

export const exportSales = () => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	postAction: 'download', // TODO refactor
	request: 'exportSales',
	urlPrefix: 'admin/',
	url: 'sale/export'
});

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const uploadDocument = file => ({
	type: UPLOAD_DOCUMENT,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.POST,
			requestType: 'multipart/form-data',
			responseType: 'json',
			url: SalesUrlBuilder.uploadDocument(),
			data: {file}
		},
		postprocess: action => {
			const {http: {response: {body}}} = action;
			const {fileName: filename} = body;

			return {
				...action,
				filename
			};
		}
	}
});

export const downloadCompromisDocument = id => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	postAction: 'download',
	request: 'downloadCompromisDocument',
	url: `sale/${id}/compromis`
});

export const downloadCompromisDocumentAdmin = id => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	postAction: 'download',
	request: 'downloadCompromisDocument',
	url: `admin/sale/${id}/compromis`
});

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const deleteDocument = (id, type) => ({
	type: DELETE_DOCUMENT,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.DELETE,
			url: SalesUrlBuilder.deleteDocument(id, type)
		}
	}
});

export const getAnnualCommission = () => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	postAction: 'getAnnualCommission',
	resourceType: 'annualCommission',
	request: 'read',
	url: 'sale/annualCommission'
});

export const getSalesChart = (requestType = SaleGroups.ALL_SALES, urlPrefix = '') => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	postAction: 'getSalesChart',
	resourceType: 'salesChart',
	request: 'read',
	url: 'sale/chart',
	urlPrefix,
	data: {
		requestType
	}
});

export const CHECK_MANDATE_NUMBER = 'CHECK_MANDATE_NUMBER';
export const checkMandateNumber = data => ({
	type: CHECK_MANDATE_NUMBER,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: SalesUrlBuilder.checkMandate(),
			data
		}
	}
});

export const DELETE_AGENTS_SALE = 'DELETE_AGENTS_SALE';
export const deleteAgentsSale = saleId => ({
	type: DELETE_AGENTS_SALE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.DELETE,
			url: SalesUrlBuilder.deleteSale(saleId)
		}
	}
});

export const GET_SEXTANT_SALE = 'GET_SEXTANT_SALE';
export const getSextantSaleData = propertyId => ({
	type: GET_SEXTANT_SALE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: SalesUrlBuilder.getSaleDataFromSextant(propertyId)
		}
	}
});

