import {HttpMethods} from '../../domain/http';

export const READ_COMMISSION_SETTINGS = 'READ_COMMISSION_SETTINGS';
export const readCommissionSettings = agentId => ({
	type: READ_COMMISSION_SETTINGS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: '/agent/commission-settings',
			data: {
				agentId, // undefined means return all
			}
		},
		postprocess: action => {
			const {http: {response: {body}}} = action;
			const identifier = agentId || 'all';

			action.data = {id: identifier, content: body};

			return action;
		}
	}
});

export const RESET_COMMISSION_SETTINGS = 'RESET_COMMISSION_SETTINGS';
export const resetCommissionSettings = (agentId = 'all') => ({
	type: RESET_COMMISSION_SETTINGS,
	data: {agentId}
});
