const DESKTOP_HEADER_HEIGHT = 180;
const MOBILE_MENU_HEIGHT = 137;

export default theme => ({
	root: {
		margin: '0 auto',
		marginTop: theme.spacing.unit * 3,
		display: 'flex',
		justifyContent: 'center'
	},
	indicator: {
		zIndex: 100,
		display: 'block !important'
	},
	pageCenter: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		minHeight: `calc(100vh - ${DESKTOP_HEADER_HEIGHT}px)`,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			minHeight: `calc(100vh - ${MOBILE_MENU_HEIGHT}px)`
		}
	}
});
