import React from 'react';
import PropTypes from 'prop-types';
import {I18nextProvider} from 'react-i18next';

import i18n from './i18n';

const Provider = ({children}) => (
	<I18nextProvider i18n={i18n}>
		{children}
	</I18nextProvider>
);

Provider.propTypes = {
	children: PropTypes.node.isRequired
};

export default Provider;
