import PropTypes from 'prop-types';

const CHALLENGE = 'Challenge';
const ADMINS = 'Admin';
const NETWORK = 'Network';
const SALES = 'Sale';
const AGENT_NETWORK_CAPACITY_INCREASED = 'AgentNetworkCapacityIncreased';
const asArray = [CHALLENGE, ADMINS, NETWORK, SALES, AGENT_NETWORK_CAPACITY_INCREASED];

export default ({
	CHALLENGE,
	ADMINS,
	NETWORK,
	SALES,
	AGENT_NETWORK_CAPACITY_INCREASED,
	asArray,
	propType: PropTypes.oneOf(asArray)
});
