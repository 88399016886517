import {connect} from 'react-redux';
import {showNotification, hideNotification} from '../../../redux/actions/notifications';
import Notification from './notification';

function mapStateToProps(state) {
	return {
		...state.notification
	};
}

function mapDispatchToProps(dispatch) {
	return {
		showNotification: data => dispatch(showNotification(data)),
		hide: () => dispatch(hideNotification())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
