import PropTypes from 'prop-types';
import BuyerType from './buyer-type';

const SELLER = 'tracfinSeller';
const SELLER_APPROVED = 'tracfinSellerApproved';
const BUYER = 'tracfinBuyer';
const BUYER_STATUS = 'tracfinBuyerStatus';
const BUYER_STATUS_APPROVED = 'tracfinBuyerStatusApproved';

const MYNOTARY = 'tracfinMynotary';
const MYNOTARY_DOCUMENT = 'tracfinMynotaryDocument';

const BUYER_COMPANY_KEY = BuyerType.COMPANY;
const BUYER_COMPANY_NAME = 'name';
const BUYER_COMPANY_KBIS = 'kbis';
const BUYER_COMPANY_STATUTES = 'statutes';
const BUYER_COMPANY_BENEFICIARY_OWNER = 'inpi';
const BUYER_COMPANY_BENEFICIARY_ADDRESS = 'additionalDocuments';
const BUYER_COMPANY_BENEFICIARY_LIST = 'beneficiaries';
const BUYER_COMPANY_BENEFICIARY_KEY = 'name';
const BUYER_COMPANY_BENEFICIARY_PASSPORT = 'passport';
const BUYER_COMPANY_BENEFICIARY_PASSPORT_ADDITIONAL = 'passportAdditional';

const BUYER_INDIVIDUAL_KEY = BuyerType.INDIVIDUAL;
const BUYER_INDIVIDUAL_NAME = 'name';
const BUYER_INDIVIDUAL_PASSPORT = 'passport';
const BUYER_INDIVIDUAL_PASSPORT_ADDITIONAL = 'passportAdditional';
const BUYER_INDIVIDUAL_PROOF_OF_ADDRESS = 'proofOfAddress';

const VIGILANCE = 'vigilance';
const ENHANCED_VIGILANCE = 'enhancedVigilance';
const ENHANCED_VIGILANCE_REQUIRED = 'enhancedVigilanceRequired';

const companyFieldsAsArray = [BUYER_COMPANY_KBIS, BUYER_COMPANY_STATUTES, BUYER_COMPANY_BENEFICIARY_OWNER]; // BUYER_COMPANY_BENEFICIARY_ADDRESS
const beneficiaryFieldsAsArray = [BUYER_COMPANY_BENEFICIARY_PASSPORT, BUYER_COMPANY_BENEFICIARY_PASSPORT_ADDITIONAL];
const individualFieldsAsArray = [BUYER_INDIVIDUAL_PASSPORT, BUYER_INDIVIDUAL_PASSPORT_ADDITIONAL, BUYER_INDIVIDUAL_PROOF_OF_ADDRESS];

const asArray = [SELLER, SELLER_APPROVED, BUYER, BUYER_STATUS, BUYER_STATUS_APPROVED];

export default ({
	SELLER,
	SELLER_APPROVED,
	BUYER,
	BUYER_STATUS,
	BUYER_STATUS_APPROVED,

	MYNOTARY,
	MYNOTARY_DOCUMENT,

	BUYER_COMPANY_KEY,
	BUYER_COMPANY_NAME,
	BUYER_COMPANY_KBIS,
	BUYER_COMPANY_STATUTES,
	BUYER_COMPANY_BENEFICIARY_OWNER,
	BUYER_COMPANY_BENEFICIARY_ADDRESS,
	BUYER_COMPANY_BENEFICIARY_LIST,
	BUYER_COMPANY_BENEFICIARY_KEY,
	BUYER_COMPANY_BENEFICIARY_PASSPORT,
	BUYER_COMPANY_BENEFICIARY_PASSPORT_ADDITIONAL,

	BUYER_INDIVIDUAL_KEY,
	BUYER_INDIVIDUAL_NAME,
	BUYER_INDIVIDUAL_PASSPORT,
	BUYER_INDIVIDUAL_PASSPORT_ADDITIONAL,
	BUYER_INDIVIDUAL_PROOF_OF_ADDRESS,

	VIGILANCE,
	ENHANCED_VIGILANCE,
	ENHANCED_VIGILANCE_REQUIRED,

	companyFieldsAsArray,
	beneficiaryFieldsAsArray,
	individualFieldsAsArray,

	asArray,
	propType: PropTypes.oneOf(asArray)
});
