import i18n from 'i18next';
import format from './format';
import english from './en';
import french from './fr';

/* eslint-disable quote-props */

const LANGUAGE_STORAGE_KEY = 'MySextant-Language';

const saveLanguage = lng => localStorage.setItem(LANGUAGE_STORAGE_KEY, lng);
const loadLanguage = () => localStorage.getItem(LANGUAGE_STORAGE_KEY);

i18n
	.init({
		resources: {
			en: {
				...english
			},
			fr: {
				...french
			}
		},
		lng: loadLanguage(),
		fallbackLng: 'fr',
		debug: process.env.NODE_ENV !== 'production',

		ns: ['basic'],
		defaultNS: 'basic',
		keySeparator: '.',

		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ',',
			format,
			prefix: '!#!',
			suffix: '!#!'
		},

		react: {
			wait: true
		}
	});

i18n.on('languageChanged', lng => saveLanguage(lng));

export default i18n;
