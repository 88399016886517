import {
	ADMIN_ENDPOINT,
	ANONYMOUS_ENDPOINT,
	AGENT_ENDPOINT
} from './endpoints';
import {UserRoles} from '../../domain/user';

const endpointsMap = [
	{predicate: roles => !roles || roles.length === 0, endpoint: ANONYMOUS_ENDPOINT},
	{predicate: UserRoles.hasAdminRole, endpoint: ADMIN_ENDPOINT},
	{predicate: UserRoles.hasAgentRole, endpoint: AGENT_ENDPOINT}
];

export default (sourceUrl, roles) => {
	if (sourceUrl.charAt(0) !== '/') {
		sourceUrl = '/' + sourceUrl;
	}

	for (let i = 0; i < endpointsMap.length; i++) {
		const entry = endpointsMap[i];

		if (entry.predicate(roles)) {
			return `${entry.endpoint}${sourceUrl}`;
		}
	}

	throw new Error(`Unsupported roles: ${roles.join(',')}`);
};
