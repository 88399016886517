import findIndex from 'lodash/findIndex';

const SESSION_STORAGE_INSTANCE = 'KEROS_PATH_HISTORY';

export function getInitialPathHistory() {
	const result = {
		pathHistoryIndex: 0,
		pathHistory: [],
		historyDifference: 0
	};

	return result;
}

function getFromStorage() {
	const storage = sessionStorage.getItem(SESSION_STORAGE_INSTANCE);
	const result = {
		isEmpty: true,
		value: {}
	};

	if (storage) {
		const parsedStorage = storage.split('|');

		if (parsedStorage.length === 3) {
			result.isEmpty = false;
			result.value.pathHistoryIndex = parseInt(parsedStorage[0], 10);
			result.value.pathHistory = parsedStorage[1].split(',');
			result.value.historyDifference = parseInt(parsedStorage[2], 10);
		}
	}

	return result;
}

function setInStorage(result) {
	sessionStorage.setItem(
		SESSION_STORAGE_INSTANCE,
		`${result.pathHistoryIndex}|${result.pathHistory.join(',')}|${result.historyDifference}`
	);

	return result;
}

export function getUpdatedPathHistory(state, location) {
	const result = {
		pathHistoryIndex: state.pathHistoryIndex,
		pathHistory: state.pathHistory.slice()
	};

	if (result.pathHistory.length === 0) {
		// first time
		// check if something in Storage and also need to check if difference.
		// the difference can only be changed by users actions
		// (moved to other website and move back via inserting an address)
		const dataInStorage = getFromStorage();

		if (!dataInStorage.isEmpty) {
			const currentDifference = window.history.length - dataInStorage.value.pathHistory.length;

			if (dataInStorage.value.historyDifference === currentDifference) {
				return setInStorage(dataInStorage.value);
			}
			// eslint-disable-next-line
			console.error('history was changed:','WAS:', dataInStorage.value.historyDifference, 'NOW:', currentDifference)
		}

		result.pathHistory.push(location.key);
		result.historyDifference = window.history.length - result.pathHistory.length;

		return setInStorage(result);
	}

	const indexFound = findIndex(result.pathHistory, key => key === location.key);

	if (indexFound > -1) {
		result.pathHistoryIndex = indexFound;
	} else {
		result.pathHistoryIndex += 1;

		if (location.action !== 'POP' && result.pathHistoryIndex < result.pathHistory.length) {
			result.pathHistory.splice(result.pathHistoryIndex);
		}

		result.pathHistory.push(location.key);
	}

	result.historyDifference = window.history.length - result.pathHistory.length;
	return setInStorage(result);
}

export function isBackDisabled(state) {
	return state.pathHistoryIndex === 0;
}
