import PropTypes from 'prop-types';

const EMPTY = 'empty';
const INDIVIDUAL = 'individual';
const COMPANY = 'company';

const asArray = [EMPTY, INDIVIDUAL, COMPANY];

export default ({
	EMPTY,
	INDIVIDUAL,
	COMPANY,
	asArray,
	propType: PropTypes.oneOf(asArray)
});
