import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CircularProgress from '../components/common/loading-indicator';
import {setAuthData} from '../redux/actions/auth';
import userManager from './auth-client';

const GET_USER_INFO_TIMER = 60000; // 1 min
const updateUserTimerFn = async setData => {
	const userData = await userManager.getUserAsync(true);

	setData(userData);
};

function mapDispatchToProps(dispatch) {
	return {
		setData: data => dispatch(setAuthData(data))
	};
}

class UserProvider extends React.Component {
	async componentDidMount() {
		this._mount = true;

		const {setData} = this.props;
		const userData = await userManager.getUserAsync();
		const {skipLanding} = userManager.getUser();

		await setData({...userData, skipLanding});

		if (this._mount) {
			if (!this.timer) {
				this.timer = setInterval(() => updateUserTimerFn(setData), GET_USER_INFO_TIMER);
			}

			await this.setState({startedUp: true});
		}
	}

	componentWillUnmount() {
		this._mount = false;

		if (this.timer) {
			clearInterval(this.timer);

			this.timer = null;
		}
	}

	render() {
		const {startedUp} = this.state;

		if (!startedUp) {
			return <CircularProgress size={80} pageCenter/>;
		}

		const {children} = this.props;

		return children;
	}

	state = {startedUp: false}
}

UserProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default connect(null, mapDispatchToProps)(UserProvider);
