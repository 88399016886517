import React from 'react';

export default function asyncComponent(getComponent) {
	class AsyncComponent extends React.Component {
		componentWillMount() {
			const {Component: component} = this.state;

			if (!component) {
				getComponent().then(Component => {
					AsyncComponent.Component = Component;
					this.setState({Component});
				});
			}
		}

		render() {
			const {Component} = this.state;

			if (Component) {
				return <Component {...this.props} />;
			}
			return null;
		}

		static Component = null;

		state = {Component: AsyncComponent.Component};
	}
	return AsyncComponent;
}
