import {HttpMethods} from '../../domain/http';
import {ChallengesUrlBuilder} from '../../api/url-builder';

export const LIST_CHALLENGE = 'LIST_CHALLENGE';
export const listChallenge = type => ({
	type: LIST_CHALLENGE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: ChallengesUrlBuilder.listChallenge(type)
		},
		postprocess: action => {
			const {http: {response: {body}}} = action;

			action.data = {type, content: body};
			return action;
		}
	}
});

export const READ_CHALLENGE = 'READ_CHALLENGE';
export const readChallenge = (type, id) => ({
	type: READ_CHALLENGE,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: ChallengesUrlBuilder.readChallenge(type, id)
		},
		postprocess: action => {
			const {http: {response: {body}}} = action;

			action.data = {type, id, content: body};
			return action;
		}
	}
});

export const RESET_CHALLENGE = 'RESET_CHALLENGE';
export const resetChallenge = type => ({
	type: RESET_CHALLENGE,
	data: {type}
});
