import {actionTypes, getResources} from 'redux-resource';
import {isResourceCommand, REORDER_RESOURCE} from '../actions/resource-commands';

const DEFAULT_COMPARATOR = (a, b) => b.id - a.id;

function reorderResource(action, next, store) {
	const state = store.getState();
	const {
		resourceType, comparator = DEFAULT_COMPARATOR, list: listName
	} = action;

	const resource = state[resourceType + 's']; // TODO fix me after fix resource-middleware
	const content = getResources(resource, listName);

	const list = content.sort(comparator).map(x => x.id);

	return next({
		type: actionTypes.UPDATE_RESOURCES,
		resourceType,
		lists: {[resourceType]: {[listName]: list}}
	});
}

export default store => next => action => {
	if (!isResourceCommand(action)) {
		return next(action);
	}

	if (action.type === REORDER_RESOURCE) {
		return reorderResource(action, next, store);
	}

	return next(action);
};
