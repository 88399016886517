import React from 'react';
import {Switch, Route} from 'react-router';
import WithAuth from './with-auth';
import asyncComponent from '../utils/async-component';

const Layout = asyncComponent(() => import('../components/admin/layout')
	.then(module => module.default)
);

const Dashboard = asyncComponent(() => import('../components/admin/pages/dashboard')
	.then(module => module.default)
);

const SalesPage = asyncComponent(() => import('../components/admin/pages/sales')
	.then(module => module.default)
);

const AddNewSalePage = asyncComponent(() => import('../components/admin/pages/add-new-sale')
	.then(module => module.default)
);

const SalesChartPage = asyncComponent(() => import('../components/admin/pages/sales-chart')
	.then(module => module.default)
);

const SaleDetailsPage = asyncComponent(() => import('../components/admin/pages/sale-details')
	.then(module => module.default)
);

const AgentsTablePage = asyncComponent(() => import('../components/admin/pages/agents-table')
	.then(module => module.default)
);

const EditSaleDetailsPage = asyncComponent(() => import('../components/admin/pages/edit-sale-details')
	.then(module => module.default)
);

const EventFeed = asyncComponent(() => import('../components/admin/pages/event-feed')
	.then(module => module.default)
);

const ViewAgentDashboardPage = asyncComponent(() => import('../components/admin/pages/view-agent-dashboard')
	.then(module => module.default)
);

const InvoicesPage = asyncComponent(() => import('../components/admin/pages/invoices')
	.then(module => module.default)
);

const ProformaInvoiceDetailsPage = asyncComponent(() => import('../components/admin/pages/proforma-invoice-details')
	.then(module => module.default)
);

const NotaryInvoiceDetailsPage = asyncComponent(() => import('../components/admin/pages/notary-invoice-details')
	.then(module => module.default)
);

const SextantInvoiceDetailsPage = asyncComponent(() => import('../components/admin/pages/sextant-invoice-details')
	.then(module => module.default)
);

const NotFound = asyncComponent(() => import('../components/common/not-found')
	.then(module => module.default)
);

const AccessDenied = asyncComponent(() => import('../components/common/access-denied')
	.then(module => module.default)
);

const Support = asyncComponent(() => import('../components/common/support')
	.then(module => module.default)
);

const ChallengeSalesPage = asyncComponent(() => import('../components/client/pages/challenge-sales')
	.then(module => module.default)
);

const ChallengeMandatesPage = asyncComponent(() => import('../components/client/pages/challenge-mandates')
	.then(module => module.default)
);

// const ChallengeRecruitmentPage = asyncComponent(() => import('../components/client/pages/challenge-recruitment')
// 	.then(module => module.default)
// );

const FAQPage = asyncComponent(() => import('../components/common/faqs')
	.then(module => module.default)
);

const AdminRoutes = () => (
	<WithAuth roleCheck={roles => roles.includes('admin')}>
		<Layout>
			<Switch>
				<Route exact path="/admin" component={Dashboard}/>
				<Route exact path="/admin/agents" component={AgentsTablePage}/>
				<Route exact path="/admin/agents/:id" component={ViewAgentDashboardPage}/>
				<Route exact path="/admin/sales" component={SalesPage}/>
				<Route exact path="/admin/sales/add" component={AddNewSalePage}/>
				<Route exact path="/admin/sales/chart/:status?" component={SalesChartPage}/>
				<Route exact path="/admin/sales/:id" component={SaleDetailsPage}/>
				<Route exact path="/admin/sales/:id/edit" component={EditSaleDetailsPage}/>
				<Route exact path="/admin/invoices/:type?" component={InvoicesPage}/>
				<Route exact path="/admin/invoices/proforma/:id" component={ProformaInvoiceDetailsPage}/>
				<Route exact path="/admin/invoices/notary/:id" component={NotaryInvoiceDetailsPage}/>
				<Route exact path="/admin/invoices/sextant/:id" component={SextantInvoiceDetailsPage}/>
				<Route exact path="/admin/challenges/sales" component={ChallengeSalesPage}/>
				<Route exact path="/admin/challenges/mandates" component={ChallengeMandatesPage}/>
				{/* <Route exact path="/admin/challenges/recruitment" component={ChallengeRecruitmentPage}/> */}
				<Route exact path="/admin/feed" component={EventFeed}/>
				<Route exact path="/admin/support" component={Support}/>
				<Route exact path="/admin/403" component={AccessDenied}/>
				<Route exact path="/admin/faq" component={FAQPage}/>
				<Route exact path="/admin/*" component={NotFound}/>
			</Switch>
		</Layout>
	</WithAuth>
);

export default AdminRoutes;
