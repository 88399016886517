import {SupportUrlBuilder} from '../../api/url-builder';
import {HttpMethods} from '../../domain/http';

export const READ_SUPPORT_LIST = 'READ_SUPPORT_LIST';

export const readSupportList = () => ({
	type: READ_SUPPORT_LIST,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: SupportUrlBuilder.readList()
		}
	}
});
