export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export function showNotification(message, level = 'info') {
	return {
		type: SHOW_NOTIFICATION,
		level,
		message
	};
}

export function hideNotification() {
	return {
		type: HIDE_NOTIFICATION
	};
}
