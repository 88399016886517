import {
	READ_COMMISSION_SETTINGS, RESET_COMMISSION_SETTINGS
} from '../actions/commission-settings';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case READ_COMMISSION_SETTINGS:
			return {
				...state,
				[action.data.id]: action.data.content
			};
		case RESET_COMMISSION_SETTINGS:
			return initialState;
		default:
			return state;
	}
};
