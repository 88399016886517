import {
	READ_SALES_CHART, RESET_SALES_CHART
} from '../actions/sales-chart';
import {SaleStatus} from '../../domain/sale';

const initialState = {};

function processChartData(values, year, monthIndex) {
	const response = {
		year,
		monthIndex,
		max: 0,
		totalCount: 0,
		content: [],
		groupCommission: SaleStatus.asArray.reduce((acc, status) => {
			acc[status] = {count: 0, turnover: 0, commission: 0};
			return acc;
		}, {})
	};

	if (!values) {
		return response;
	}

	for (let i = 0; i < values.length; i += 1) {
		const monthData = values[i];

		response.content[i] = {
			total: {count: 0, turnover: 0, commission: 0},
			date: monthData.date
		};

		SaleStatus.asArray.forEach(status => {
			response.content[i][status] = {};
			['count', 'turnover', 'commission'].forEach(property => {
				response.content[i][status][property] = monthData[status][property];
				response.content[i].total[property] += monthData[status][property];
				response.groupCommission[status][property] += monthData[status][property];
			});
		});

		/* max sales count per month during a year, which is used for chart bar's height calculation */
		if (response.content[i].total.count > response.max) {
			response.max = response.content[i].total.count;
		}
	}

	response.totalCount = Object.values(response.groupCommission).reduce((acc, cur) => (acc += cur.count), 0);

	return response;
}

export default (state = initialState, action) => {
	switch (action.type) {
		case READ_SALES_CHART:
			return {
				...state,
				[action.data.id]: processChartData(action.data.content, action.data.year, action.data.monthIndex)
			};
		case RESET_SALES_CHART:
			return {
				...state,
				[action.data.id]: undefined
			};
		default:
			return state;
	}
};
