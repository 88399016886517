import PropTypes from 'prop-types';

const SALES = 'sales';
const MANDATES = 'mandates';
const RECRUITMENT = 'recruitment';

const asArray = [SALES, MANDATES, RECRUITMENT];

export default ({
	SALES,
	MANDATES,
	RECRUITMENT,
	asArray,
	propType: PropTypes.oneOf(asArray)
});

