import {SubscriptionsUrlBuilder} from '../../api/url-builder';
import {HttpMethods} from '../../domain/http';

export const AGENT_READ_PACK_GOLD_SUBSCRIPTION_PROGRESS = 'AGENT_READ_PACK_GOLD_SUBSCRIPTION_PROGRESS';
export const AGENT_READ_OPEN_MENTEE_SUBSCRIPTION_PROGRESS = 'AGENT_READ_OPEN_MENTEE_SUBSCRIPTION_PROGRESS';

export const readPackGoldSubscriptionProgress = agentId => ({
	type: AGENT_READ_PACK_GOLD_SUBSCRIPTION_PROGRESS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: SubscriptionsUrlBuilder.readPackGoldSubscriptionProgress(),
			data: agentId ? {agentId} : undefined
		},
		postprocess: action => {
			const {
				http: {response: {body}}
			} = action;

			action.data = {periods: body, agentId};
			return action;
		}
	}
});

export const readOpenMenteeSubscriptionProgress = agentId => ({
	type: AGENT_READ_OPEN_MENTEE_SUBSCRIPTION_PROGRESS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: SubscriptionsUrlBuilder.readOpenMenteeSubscriptionProgress(),
			data: agentId ? {agentId} : undefined
		},
		postprocess: action => {
			const {
				http: {response: {body}}
			} = action;

			action.data = {periods: body, agentId};
			return action;
		}
	}
});
