import {
	READ_PROFORMA_DETAILS,
	READ_NOTARY_DETAILS,
	READ_ADMIN_MENTEE_DETAILS
} from '../actions/agents/invoices';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case READ_ADMIN_MENTEE_DETAILS:
		case READ_NOTARY_DETAILS:
		case READ_PROFORMA_DETAILS:
			return action.http.response.body;
		default:
			return state;
	}
};
