import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {getInitialPathHistory, getUpdatedPathHistory, isBackDisabled} from './path-history';
// import parseSearchQuery from './search-query-parse';

const RouterContext = React.createContext();

class RouterContextProvider extends React.Component {
	componentDidMount() {
		const {history, location} = this.props;

		this.removeRouterListener = history.listen(
			loc => {
				this.setState(state => getUpdatedPathHistory(state, loc));

				// Google Analytics
				if (typeof window.recordPageChange === 'function') {
					window.recordPageChange(loc.pathname + loc.search);
				}
			}
		);

		this.setState(state => getUpdatedPathHistory(state, location));
	}

	componentWillUnmount() {
		this.removeRouterListener();
	}

	render() {
		const {history, location, children} = this.props;
		// const location = parseSearchQuery(notParsed); disabled for some time
		const routingStackEmpty = isBackDisabled(this.state);
		const context = {routingStackEmpty, history, location};

		return (
			<RouterContext.Provider value={context}>
				{children}
			</RouterContext.Provider>
		);
	}

	state = getInitialPathHistory();
}

RouterContextProvider.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	children: PropTypes.node
};

const RouterContextConsumer = Component => props => (
	<RouterContext.Consumer>
		{context => <Component {...props} {...context}/>}
	</RouterContext.Consumer>
);

export default {
	Consumer: RouterContextConsumer,
	Provider: withRouter(RouterContextProvider)
};
