import PropTypes from 'prop-types';

const BLOCKING_YES = 'yes';
const BLOCKING_NO = 'no';
const BLOCKING_BOTH = 'both';
const asArray = [BLOCKING_YES, BLOCKING_NO, BLOCKING_BOTH];

export default ({
	BLOCKING_YES,
	BLOCKING_NO,
	BLOCKING_BOTH,
	asArray,
	propType: PropTypes.oneOf(asArray)
});
