import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router';

const WithLayout = ({
	layout: Layout, component: Component, pageName, ...rest
}) => {
	if (!Layout) {
		return <Route {...rest} component={Component}/>;
	}

	return (
		<Route
			{...rest}
			render={props => (
				<Layout pageName={pageName} {...props}>
					<Component {...props}/>
				</Layout>
			)}
		/>
	);
};

WithLayout.propTypes = {
	component: PropTypes.func.isRequired,
	pageName: PropTypes.string,
	layout: PropTypes.func
};

export default WithLayout;
