const readSalesChart = () => '/sale/chart';
const uploadDocument = () => '/sale/document';
const readDocument = (saleId, type, agentId) => `/sale/${saleId}/document/${type}${agentId ? '/' + agentId : ''}`;
const readCompanyDocument = (saleId, docType, agentId) => `/sale/${saleId}/document/${docType}${agentId ? '/' + agentId : ''}`;
const readIndividualDocument = (saleId, docType, personId, agentId) => `/sale/${saleId}/document/${docType}/${agentId ? '/' + agentId : ''}${personId ? '?personId=' + personId : ''}`;
const deleteDocument = (saleId, type) => `/sale/${saleId}/document/${type}`;
const checkMandate = () => '/sale/mandate-check';
const deleteSale = saleId => `/sale/${saleId}`;
const getSaleDataFromSextant = propertyId => `/sale/property-details/${propertyId}`;

export default ({
	readSalesChart,
	uploadDocument,
	readDocument,
	readCompanyDocument,
	readIndividualDocument,
	deleteDocument,
	checkMandate,
	deleteSale,
	getSaleDataFromSextant
});
