// TODO REFACTOR and split to different modules
import request from 'superagent';
import {actionTypes, getResources} from 'redux-resource';
import userManager from '../../utils/auth-client';
import {showNotification} from '../actions/notifications';
import parseHeaders from '../../utils/http/parse-headers';
import {EventTypes} from '../../domain/event';

const methodMapping = {
	READ: 'get',
	CREATE: 'post',
	UPDATE: 'put',
	DELETE: 'delete'
};

function createRequest(action, token) {
	const {
		type,
		resourceType,
		urlPrefix = '',
		url,
		data,
		requestType,
		method: methodOverride
	} = action;

	const method = methodOverride || methodMapping[type.split('_')[0]];

	if (!method) {
		throw new Error(`Method ${type} not implemented`);
	}

	const address = `/api/${urlPrefix}${url || resourceType}${data && data.id ? '/' + data.id : ''}`;
	const req = request[method](address)
		.type(requestType || 'json')
		.set('Authorization', `Bearer ${token}`);

	if (action.postAction === 'download') {
		req.responseType('blob');
	}

	if (data) {
		if (method === 'get') {
			req.query(data);
		} else {
			req.send(data);
		}
	}

	return req;
}

function transformResource(body, postTransform) {
	if (postTransform) {
		body = postTransform(body);
	}

	if (Array.isArray(body)) {
		return body;
	}
	if (body.items !== null && Array.isArray(body.items)) {
		return body.items;
	}
	return [body];
}

// TODO don't use any of this shit
// TODO refactor DEPRICATED PLEASE USE http middleware
// TODO refactor DEPRICATED PLEASE USE http middleware
// TODO refactor DEPRICATED PLEASE USE http middleware
// TODO refactor DEPRICATED PLEASE USE http middleware
// TODO refactor DEPRICATED PLEASE USE http middleware
// TODO refactor DEPRICATED PLEASE USE http middleware
export default store => next => action => {
	const {
		resourceType,
		resources,
		type,
		request: actionRequest,
		list,
		requestDetails,
		postTransform,
		postAction,
		mergeListIds
	} = action;

	if (typeof resourceType === 'undefined' || action.skipResourceMiddleware) {
		return next(action);
	}

	// set status pending
	next(action);

	const requestPrefix = type.split('_')[0];

	const {auth: {token}} = store.getState();
	const req = createRequest(action, token);

	return req
		.then(res => ({
			body: res.body,
			headers: parseHeaders(res.header || {})
		}))
		.then(res => {
			const {body} = res;

			if (requestPrefix === 'DELETE') {
				next({
					type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
					resourceType,
					resources
				});
			// TODO refactor!!!
			} else if (requestDetails) {
				const {content, ...listMeta} = body;

				// if client has suddenly overflowed in pages, we need to trigger
				// it as error and resend the request if it possible.
				// The most possible case of this paging-error situation is when
				// data was deleted in database and current user want open some page,
				// that doesn't exist now.
				if (requestDetails.paged && listMeta.number > listMeta.totalCount / listMeta.size) {
					next({
						type: actionTypes[`${requestPrefix}_RESOURCES_FAILED`],
						request: actionRequest,
						resourceType,
						resources
					});

					if (listMeta.number !== 0) {
						const newAction = {
							...action,
							data: {
								...action.data
							}
						};

						newAction.data.pageNumber = 0;

						return store.dispatch(newAction);
					}
				} else {
					next({
						type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
						resourceType,
						list,
						request: actionRequest,
						resources: transformResource(content, postTransform),
						listMeta,
						count: body.count || body.length || 0,
						mergeListIds: mergeListIds || false
					});
				}
			} else if (postAction === 'download') { // TODO REFACTOR
				const {headers} = res;
				const disposition = headers.contentDisposition;
				const matches = /filename=(.*);/.exec(disposition);
				const filename = (matches != null && matches[1] ? matches[1] : 'download');

				// The actual download
				const link = document.createElement('a');

				link.href = window.URL.createObjectURL(body);
				link.download = filename;
				link.target = '_blank';

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					request: actionRequest,
					resources: []
				});
			} else if (postAction === 'uploadCompromisDocument') {
				const {compromisFileName} = body;

				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					request: actionRequest,
					resources: [{id: 'compromisFileName', compromisFileName}]
				});
			} else if (postAction === 'getAnnualCommission') {
				const {annualCommission} = body;

				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					request: actionRequest,
					resources: [{id: 0, annualCommission}]
				});
			} else if (postAction === 'getSalesChart') {
				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					request: actionRequest,
					resources: [{id: 0, data: body}]
				});
			} else if (postAction === 'createAdminEvent') {
				const {
					id,
					adminId,
					text,
					timestamp
				} = res.body;

				const state = store.getState();

				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					list,
					request: actionRequest,
					resources: [{
						id,
						adminId,
						author: {
							firstName: state.auth.firstName,
							lastName: state.auth.lastName
						},
						text,
						timestamp,
						eventType: EventTypes.ADMINS,
						comments: [],
						likes: []
					}]
				});
			} else if (postAction === 'createEventComment') {
				const {
					eventId,
					id,
					userId,
					text
				} = res.body;

				const state = store.getState();
				const oldEventsList = getResources(state.events);

				const targetEvent = oldEventsList.filter(event => event.id === eventId);

				const updatedComments = JSON.parse(JSON.stringify(targetEvent[0].comments));

				updatedComments.push({
					id,
					userId,
					firstName: state.auth.firstName,
					lastName: state.auth.lastName,
					text
				});

				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					request: actionRequest,
					resources: [{id: eventId, comments: updatedComments}]
				});
			} else if (postAction === 'updateEventLike') {
				const {
					eventId,
					userId
				} = res.body;

				const state = store.getState();
				const oldEventsList = getResources(state.events);
				const targetEvent = oldEventsList.filter(event => event.id === eventId);
				const updatedLikes = JSON.parse(JSON.stringify(targetEvent[0].likes));

				if (updatedLikes.includes(userId)) {
					updatedLikes.splice(updatedLikes.indexOf(userId), 1);
				} else {
					updatedLikes.push(userId);
				}

				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					request: actionRequest,
					resources: [{id: eventId, likes: updatedLikes}]
				});
			} else {
				next({
					type: actionTypes[`${requestPrefix}_RESOURCES_SUCCEEDED`],
					resourceType,
					list,
					request: actionRequest,
					resources: transformResource(body, postTransform),
					count: body.count || body.length || 0,
					mergeListIds: mergeListIds || false
				});
			}

			return res;
		})
		.catch(async error => {
			next({
				type: actionTypes[`${requestPrefix}_RESOURCES_FAILED`],
				request: actionRequest,
				resourceType,
				resources
			});

			if (error.status === 500) {
				next(showNotification(error.response.statusText, 'error'));

				return Promise.resolve(error.response.statusText);
			}

			if (error.status === 400) {
				next(showNotification(
					error.response.body.errors.map(item => `${item.name}: ${item.message}`),
					'error'
				));

				return Promise.resolve(error.response.body);
			}

			if (error.status === 401) {
				userManager.logOut();
				// await next(logout());
				// next(showNotification('signed-out', 'error'));

				return Promise.resolve(error.response.body);
			}

			if (error.message) {
				console.error(`${error.message} ${error.stack}`); // eslint-disable-line
			}

			return Promise.resolve(error);
		});
};
