import PropTypes from 'prop-types';

const EMPTY = 'empty';
const PROGRESS = 'progress';
const VERIFICATION = 'verification';
const APPROVED = 'approved';
const asArray = [EMPTY, PROGRESS, VERIFICATION, APPROVED];

export default ({
	EMPTY,
	PROGRESS,
	VERIFICATION,
	APPROVED,
	asArray,
	propType: PropTypes.oneOf(asArray)
});
