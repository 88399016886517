import 'typeface-roboto'; // eslint-disable-line import/extensions
import 'react-virtualized-select/styles.css';
import 'react-select/dist/react-select.css';

import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
// import {ConnectedRouter} from 'react-router-redux';
import {ConnectedRouter} from 'connected-react-router';
import {Route, Switch} from 'react-router';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import {pdfjs} from 'react-pdf';
import SiginInCallback from './components/common/signin-callback';
import SilentSigninCallback from './components/common/signin-silent-callback';

import UserProvider from './utils/user-provider';
// import ThemeLayout from './components/common/theme';
import {StylesProvider, createGenerateClassName} from '@material-ui/styles';
import Routes from './routes';
import I18NProvider from './i18n';
import createStore from './redux/store';
import history from './redux/history';
import backUrlManager from './utils/back-url-helper';

import './app.css';

const ignoredPaths = ['/', '/sign-in', '/signin-callback', '/silent-signin-callback'];

// backUrl
if (!ignoredPaths.includes(window.location.pathname)) {
	backUrlManager.setOnce(`${window.location.pathname}${window.location.search}`);
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const store = createStore();

const generateClassName = createGenerateClassName({
	productionPrefix: 'c',
	disableGlobal: true
});

const App = () => (
	<I18NProvider>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<StylesProvider generateClassName={generateClassName}>
				<ReduxProvider store={store}>
					<ConnectedRouter history={history}>
						<Switch>
							<Route exact path="/signin-callback" component={SiginInCallback}/>
							<Route exact path="/silent-signin-callback" component={SilentSigninCallback}/>
							<UserProvider>
								<Routes />
							</UserProvider>
						</Switch>
					</ConnectedRouter>
				</ReduxProvider>
			</StylesProvider>
		</MuiPickersUtilsProvider>
	</I18NProvider>
);

export default App;
