import {
	VIEW_EVENT_FEED,
	GET_UNREAD_EVENTS
} from '../actions/events';

const initialState = 0;

export default (state = initialState, action) => {
	switch (action.type) {
		case VIEW_EVENT_FEED:
			return initialState;
		case GET_UNREAD_EVENTS:
			return action.data;
		default:
			return state;
	}
};
