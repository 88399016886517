import React from 'react';
import {Route, Switch} from 'react-router';
import WithAuth from './with-auth';
import WithLayout from './with-layout';
import RoutingContext from './routing-context';
import AdminRoutes from './admin';
import ClientRoutes from './client';
import commonRoutes from './common';
import asyncComponent from '../utils/async-component';
import ThemeLayout from '../components/common/theme';

const NotFound = asyncComponent(() => import('../components/common/not-found')
	.then(module => module.default)
);

const Routes = () => (
	<ThemeLayout>
		<RoutingContext.Provider>
			<Switch>
				{Object.values(commonRoutes).map(route => (
					<WithLayout key={route.path} {...route}/>
				))}
				<WithAuth roleCheck={() => true}>
					<React.Fragment>
						<Route path="/admin/:module?/:group?/:item1?/:item2?" component={AdminRoutes} />
						<Route path="/app/:module?/:group?/:status?/:item?" component={ClientRoutes}/>
					</React.Fragment>
				</WithAuth>
				<Route path="*" component={NotFound}/>
			</Switch>
		</RoutingContext.Provider>
	</ThemeLayout>
);

export default Routes;
