import {
	AGENT_READ_PACK_GOLD_SUBSCRIPTION_PROGRESS,
	AGENT_READ_OPEN_MENTEE_SUBSCRIPTION_PROGRESS
} from '../actions/subscriptions';

const initialState = {progressInfo: {}};


function aggregateCommissionPerMonth({periods}) {
	return {
		entries: periods
	};
}

function updateState(state, target, action, data) {
	if (action.data.agentId) {
		return {
			...state,
			progressInfo: {
				...state.progressInfo,
				[target]: {
					...state.progressInfo[target],
					[action.data.agentId]: data
				}
			}
		};
	}

	return {
		...state,
		progressInfo: {
			...state.progressInfo,
			[target]: data
		}
	};
}

export default (state = initialState, action) => {
	switch (action.type) {
		case AGENT_READ_PACK_GOLD_SUBSCRIPTION_PROGRESS:
			return updateState(state, 'packGold', action, aggregateCommissionPerMonth(action.data));
		case AGENT_READ_OPEN_MENTEE_SUBSCRIPTION_PROGRESS:
			return updateState(state, 'openMentee', action, aggregateCommissionPerMonth(action.data));
		default:
			return state;
	}
};
