import {SHOW_NOTIFICATION, HIDE_NOTIFICATION} from '../actions/notifications';

const initialState = {
	open: false,
	message: null
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_NOTIFICATION:
			return Object.assign({}, state, {
				open: true,
				message: action.message
			});
		case HIDE_NOTIFICATION:
			return Object.assign({}, state, {
				open: false,
				message: null
			});
		default:
			return state;
	}
}
