import {
	READ_AGENT, RESET_AGENT,
	LIST_AGENTS, RESET_AGENTS_LIST
} from '../actions/agents';

const initialState = {profiles: {}, lists: {}};

function updateState(state, target, action) {
	return {
		...state,
		[target]: {
			...state[target],
			[action.data.id]: action.data.content
		}
	};
}

export default (state = initialState, action) => {
	switch (action.type) {
		case READ_AGENT:
		case RESET_AGENT:
			return updateState(state, 'profiles', action);
		case LIST_AGENTS:
		case RESET_AGENTS_LIST:
			return updateState(state, 'lists', action);
		default:
			return state;
	}
};
