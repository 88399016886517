import basic from './basic.json';
import controls from './controls.json';
import dialogs from './dialogs';
import navigation from './navigation.json';
import validation from './validation.json';
import events from './events.json';
import pages from './pages.json';
import tables from './tables.json';
import challenges from './challenges';
import reports from './reports';

export default {
	basic,
	pages,
	controls,
	events,
	dialogs,
	navigation,
	validation,
	challenges,
	tables,
	reports
};

