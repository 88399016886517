import request from 'superagent';
import {getAccessToken} from "./token-provider";

export type HttpRequestParams = {
	url: string,
	requestType?: 'multipart/form-data' | 'json',
	responseType?: 'blob' | 'json',
	method?: 'get' | 'post' | 'delete' | 'put',
	token?: string,
	data: any,
	headers?: Record<string, string>,
};

function makeRequest(params: HttpRequestParams) {
	const {
		url = '',
		requestType = 'json',
		method = 'get',
		token = getAccessToken(),
		data = {},
		responseType = 'json',
		headers = {}
	} = params;

	if (!url) return;

	const req = request[method](url);

	Object.entries(headers).forEach(([key, value]) => {
		req.set(key, value);
	});

	if (requestType !== 'multipart/form-data') {
		req.type(requestType);
	}

	if (token) {
		req.set('Authorization', `Bearer ${token}`);
	}

	req.responseType(responseType);

	if (requestType === 'multipart/form-data') {
		const formData = new FormData();
		const {file, name = '', documentType = ''} = data;

		formData.append('file', file);
		formData.append('name', name);
		formData.append('type', documentType);

		req.send(formData);

		return req;
	}

	if (data) {
		if (method === 'get') {
			req.query(data);
		} else {
			req.send(data);
		}
	}

	return req;
}

export default makeRequest;
