import PropTypes from 'prop-types';

const GAFI = {
	key: 'gafi',
	url: 'https://www.fatf-gafi.org/fr/pays/#FATF'
};
const ASSETS_FREEZE = {
	key: 'assets-freeze',
	url: 'https://gels-avoirs.dgtresor.gouv.fr/List'
};
const INPI = {
	key: 'inpi',
	url: 'https://data.inpi.fr/'
};

const list = [GAFI, INPI];
const enhancedList = [GAFI, ASSETS_FREEZE, INPI];

export default ({
	GAFI,
	ASSETS_FREEZE,
	INPI,
	list,
	enhancedList
});
