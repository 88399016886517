import {compose} from 'recompose';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import WithAuth from './with-auth';
import userManager from '../../utils/auth-client';

const mapStateToProps = (state, {roleCheck}) => ({
	auth: state.auth,
	isAuthorized: !!state.auth.token,
	isRoleAcceptable: roleCheck(state.auth.roles),
	location: state.router.location,
	skipLanding: userManager.getUser().skipLanding
});

const enhance = compose(
	withRouter,
	connect(mapStateToProps)
);

export default enhance(WithAuth);
