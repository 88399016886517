import {actionTypes} from 'redux-resource';
import {AgentsUrlBuilder} from '../../api/url-builder';
import {HttpMethods} from '../../domain/http';

const resourceType = 'agent';

export const READ_AGENT = 'READ_AGENT';
export const readAgent = id => ({
	type: READ_AGENT,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: AgentsUrlBuilder.readAgent(id)
		},
		postprocess: action => {
			const {
				http: {response: {body}}
			} = action;

			action.data = {id, content: body};
			return action;
		}
	}
});

export const RESET_AGENT = 'RESET_AGENT';
export const resetAgent = id => ({
	type: RESET_AGENT,
	data: {
		id
	}
});

export const LIST_AGENTS = 'LIST_AGENTS';
export const listAgents = ({
	pageSize, pageNumber, blocked, searchString
}, listName = 'default') => ({
	type: LIST_AGENTS,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: AgentsUrlBuilder.listAgents(),
			data: {
				pageSize,
				pageNumber,
				blocked,
				searchString
			}
		},
		postprocess: action => {
			const {
				http: {response: {body}}
			} = action;

			action.data = {id: listName, content: body};
			return action;
		}
	}
});

export const RESET_AGENTS_LIST = 'RESET_AGENTS_LIST';
export const resetAgentsList = (listName = 'default') => ({
	type: RESET_AGENTS_LIST,
	data: {
		id: listName
	}
});

export const findAgentsByPrefix = (prefix, type) => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'autocomplete',
	urlPrefix: 'admin/',
	url: 'agent/autocomplete',
	list: `autocomplete-${type}`,
	mergeListIds: false,
	requestDetails: {
		searched: true
	},
	data: {
		searchString: prefix,
		type
	}
});

export const findAgentsByPrefixNetwork = prefix => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'autocomplete',
	url: 'agent/autocomplete',
	list: 'autocomplete',
	mergeListIds: false,
	requestDetails: {
		searched: true
	},
	data: {
		searchString: prefix
	}
});

export const readMyNetwork = () => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'readNetwork',
	url: 'agent/network',
	list: 'network',
	data: {
		excludeRoot: true
	}
});

export const readNetwork = id => ({
	type: actionTypes.READ_RESOURCES_PENDING,
	resourceType,
	request: 'readNetwork',
	url: 'admin/agent/network',
	list: 'network',
	data: {
		rootId: id,
		excludeRoot: true
	}
});

export const addGodson = data => ({
	type: actionTypes.CREATE_RESOURCES_PENDING,
	resourceType,
	request: 'create',
	url: 'agent/add-godson',
	data
});
