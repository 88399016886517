import Layout from '../components/common/layout';
import asyncComponent from '../utils/async-component';

const LandingPage = asyncComponent(() => import('../components/common/landing-page')
	.then(module => module.default)
);

const Login = asyncComponent(() => import('../components/common/login')
	.then(module => module.default)
);

const AccessDenied = asyncComponent(() => import('../components/common/access-denied')
	.then(module => module.default)
);

const AccessDeniedUnverifiedAgent = asyncComponent(() => import('../components/common/access-denied-unverified-agent')
	.then(module => module.default)
);

export default {
	landingPage: {
		exact: true,
		path: '/landing-page',
		pageName: 'Keros',
		component: LandingPage
	},
	login: {
		exact: true,
		path: '/login',
		pageName: 'Login',
		component: Login,
		layout: Layout
	},
	accessDeniedForUnverifiedAgent: {
		exact: true,
		path: '/403-unverified-agent',
		pageName: 'Access denied',
		component: AccessDeniedUnverifiedAgent,
		layout: Layout
	},
	accessDenied: {
		exact: true,
		path: '/403',
		pageName: 'Access denied',
		component: AccessDenied,
		layout: Layout
	}
};
