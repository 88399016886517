import {
	SET_TABS, RESET_TABS,
	SET_TITLE, RESET_TITLE,
	DISABLE_DEFAULT_LAYOUT,
	ENABLE_DEFAULT_LAYOUT,
	DISABLE_FOOTER, ENABLE_FOOTER
} from '../actions/agents/layout';

const initialState = {
	tabs: null,
	title: null,
	disableDefaultLayout: false,
	disableFooter: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_TABS:
			return {
				...state,
				tabs: action.tabs
			};
		case RESET_TABS:
			return {...state, tabs: null};
		case SET_TITLE:
			return {...state, title: action.title};
		case RESET_TITLE:
			return {...state, title: null};
		case DISABLE_DEFAULT_LAYOUT:
			return {...state, disableDefaultLayout: true};
		case ENABLE_DEFAULT_LAYOUT:
			return {
				...state,
				disableDefaultLayout: false,
				disableFooter: false
			};
		case DISABLE_FOOTER:
			return {...state, disableFooter: true};
		case ENABLE_FOOTER:
			return {...state, disableFooter: false};
		default:
			return state;
	}
};
