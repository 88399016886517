import {
	GET_SEXTANT_SALE
} from '../actions/sales';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SEXTANT_SALE:
			return {
				...state,
				...action.http.response.body
			};
		default:
			return state;
	}
};
