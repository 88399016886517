import {
	LIST_CHALLENGE, READ_CHALLENGE, RESET_CHALLENGE
} from '../actions/challenges';
import {ChallengeType} from '../../domain/challenge';

const initialState = ChallengeType.asArray.reduce((state, type) => {
	state[type] = {};
	return state;
}, {});

const updateState = (state, type, target, content) => ({
	...state,
	[type]: {
		...state[type],
		[target]: content
	}
});

export default (state = initialState, action) => {
	switch (action.type) {
		case LIST_CHALLENGE:
			return updateState(state, action.data.type, 'list', action.data.content);
		case READ_CHALLENGE:
			return updateState(state, action.data.type, action.data.id, action.data.content);
		case RESET_CHALLENGE:
			return {
				...state,
				[action.data.type]: {}
			};
		default:
			return state;
	}
};
