import {SalesUrlBuilder} from '../../api/url-builder';
import {HttpMethods} from '../../domain/http';
import {SaleGroups} from '../../domain/sale/index';

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH_INDEX = new Date().getMonth();

export const READ_SALES_CHART = 'READ_SALES_CHART';
export const readSalesChart = (requestType = SaleGroups.ALL_SALES, agentId, year = CURRENT_YEAR, monthIndex = CURRENT_MONTH_INDEX) => ({
	type: READ_SALES_CHART,
	http: {
		withToken: true,
		request: {
			method: HttpMethods.GET,
			url: SalesUrlBuilder.readSalesChart(),
			data: {
				requestType,
				agentId, // undefined means return all
				year,
				monthIndex
			}
		},
		postprocess: action => {
			const {http: {response: {body}}} = action;
			const identifier = agentId || 'all';

			action.data = {id: identifier, content: body, year, monthIndex};
			return action;
		}
	}
});

export const RESET_SALES_CHART = 'RESET_SALES_CHART';
export const resetSalesChart = (agentId = 'all') => ({
	type: RESET_SALES_CHART,
	data: {agentId}
});
