import React from 'react';
import {Redirect} from 'react-router';
import PropTypes from 'prop-types';

import backUrlManager from '../../utils/back-url-helper';

const getPrefix = roles => {
	if (roles.includes('agent')) {
		return '/app';
	}

	if (roles.includes('admin')) {
		return '/admin';
	}

	throw new Error(`Unknown role: ${roles.join(',')}`);
};

const isRightPrefix = (roles, location) => {
	if (roles.includes('agent')) {
		return location.pathname.startsWith('/app');
	}

	if (roles.includes('admin')) {
		return location.pathname.startsWith('/admin');
	}

	throw new Error(`Unknown role: ${roles.join(',')}`);
};

class WithAuth extends React.Component {
	componentDidCatch(error, errorInfo) {
		console.error("WithAuth.componentDidCatch", errorInfo, error);
	}

	render() {
		const {
			children, location, history,
			auth, isAuthorized, isRoleAcceptable,
			skipLanding
		} = this.props;

		if (isAuthorized) {
			const isUnverifiedAgent = auth.roles.includes('unverifiedAgent');
			const isLMNPAgent = auth.roles.includes('lMNPAgent');

			if (isUnverifiedAgent || isLMNPAgent) {
				return <Redirect to="/403-unverified-agent" />;
			}

			const backUrl = backUrlManager.getAndClearSoon();

			if (backUrl && backUrl !== `${window.location.pathname}${window.location.search}`) {
				return <Redirect to={backUrl || '/app'}/>;
			}

			// overwrite if no prefix or wrong prefix provided
			if (!isRightPrefix(auth.roles, location)) {
				history.replace({
					pathname: `${getPrefix(auth.roles)}${location.pathname}`,
					search: location.search,
					state: location.state
				});
				return null;
			}

			if (isRoleAcceptable) {
				return React.cloneElement(children, {auth});
			}

			return <Redirect to="/403" />;
		} if (
			location.pathname !== '/login'
			&& location.pathname !== '/signin-callback'
			&& location.pathname !== '/silent-signin-callback'
			&& location.pathname !== '/landing-page'
		) {
			return (
				skipLanding
					? <Redirect to={{pathname: '/login', state: {from: location}}}/>
					: <Redirect to={{pathname: '/landing-page', state: {from: location}}}/>
			);
		}

		return null;
	}
}

WithAuth.propTypes = {
	children: PropTypes.node.isRequired,
	auth: PropTypes.object.isRequired,
	isAuthorized: PropTypes.bool.isRequired,
	isRoleAcceptable: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	skipLanding: PropTypes.bool.isRequired
};

export default WithAuth;
