import {createMuiTheme, Theme} from '@material-ui/core/styles';
import {PaletteColor} from '@material-ui/core/styles/createPalette';
import {Shadows} from '@material-ui/core/styles/shadows';

const theme = createMuiTheme({
	// @ts-ignore
	shadows: Array(25).fill('none') as Shadows,
	typography: {
		fontFamily: [
			'Montserrat',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif'
		].join(',')
	},
	palette: {
		type: 'light',
		primary: {
			light: '#BA4065',
			main: '#BA4065',
			dark: '#92314E'
		},
		secondary: {
			main: '#0FBACD'
		},
		grey: {
			// @ts-ignore
			light: '#E9E9E9',
			main: '#C4C4C4',
			dark: '#6F6F6F',
			darker: '#4E4E4E'
		},
		yellow: '#F2C94C',
		red: '#EB5757',
		green: '#6FCF97',
		background: {
			default: "#f6f6f6"
		}
	},
	mobileUnits: {
		header: {
			height: 70
		},
		footer: {
			height: 68
		},
		tabs: {
			height: 48
		}
	},
	mobileLayout: {
		margin: '0 -16px -16px',
		padding: '24px 16px 86px', // 68 - footer height (for Safari iOS -__-)
		backgroundColor: 'white',
		boxSizing: 'border-box'
	}
});

export type MySextantTheme = Theme & {
	palette: {
		custom: {
			error: Partial<PaletteColor>,
			warning: Partial<PaletteColor>,
		}
	}
};

export default theme;