import React from 'react';
import ReactDOM from 'react-dom';
import App from './Client/app';

if (process.env.NODE_ENV !== 'production') {
	window.ssoConfiguration.baseUrl = process.env.REACT_APP_BASE_URL;
	window.ssoConfiguration.identityServer = process.env.REACT_APP_SSO_AUTHORITY;
	window.ssoConfiguration.mySextant = process.env.REACT_APP_MYSXT_BASE_URL;
}

ReactDOM.render(
	React.createElement(App),
	document.getElementById('root')
);
