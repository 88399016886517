import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
// import {routerReducer, routerMiddleware as createRouterMiddleware} from 'react-router-redux';
import {connectRouter as routerReducer, routerMiddleware as createRouterMiddleware} from 'connected-react-router';
import {resourceReducer} from 'redux-resource';
import authReducer from './reducers/auth';
import layout from './reducers/layout';
import notification from './reducers/notification';
import unreadEventsCount from './reducers/unread-events-counter';
import readSupportList from './reducers/support';
import subscriptions from './reducers/subscriptions';
import salesChart from './reducers/sales-chart';
import agents from './reducers/agents';
import reportsData from './reducers/reports';
import challenges from './reducers/challenges';
import invoiceDetails from './reducers/invoice';
import sextantSale from './reducers/sextant-sale';
import commissionSettings from './reducers/commission-settings';

import resource from './middlewares/resource';
import createActionProcessing from './middlewares/factories/action-processing-factory';
import httpMiddleware from './middlewares/http';
import resourceCommandsMiddleware from './middlewares/resource-commands';
import logger from './middlewares/logger';

import history from './history';

import listMetaPlugin from './plugins/list-meta';

const routerMiddleware = createRouterMiddleware(history);
const httpPreprocess = createActionProcessing(['http', 'preprocess']);
const httpPostprocess = createActionProcessing(['http', 'postprocess']);

const reducer = combineReducers({
	auth: authReducer,
	notification,
	layout,
	unreadEventsCount,
	readSupportList,
	subscriptions,
	salesChart,
	sextantSale,
	agents,
	reportsData,
	challenges,
	invoiceDetails,
	commissionSettings,
	agentsOld: resourceReducer('agent', { // TODO refactor resource middleware
		initialState: {listMeta: {}},
		plugins: [listMetaPlugin]
	}),
	sales: resourceReducer('sale', {
		initialState: {listMeta: {}},
		plugins: [listMetaPlugin]
	}),
	events: resourceReducer('event', {
		initialState: {listMeta: {}},
		plugins: [listMetaPlugin]
	}),
	notaryInvoices: resourceReducer('notaryInvoices', {
		initialState: {listMeta: {}},
		plugins: [listMetaPlugin]
	}),
	proformaInvoices: resourceReducer('proformaInvoices', {
		initialState: {listMeta: {}},
		plugins: [listMetaPlugin]
	}),
	sextantInvoices: resourceReducer('sextantInvoices', {
		initialState: {listMeta: {}},
		plugins: [listMetaPlugin]
	}),
	menteeInvoices: resourceReducer('menteeInvoices'),
	annualCommission: resourceReducer('annualCommission'),
	totalSalesCountCurrentYear: resourceReducer('totalSalesCountCurrentYear'),
	router: routerReducer(history),
	client: resourceReducer('client')
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
	: compose;
/* eslint-enable */

const enhancer = composeEnhancers(
	applyMiddleware(
		resource,
		resourceCommandsMiddleware,
		routerMiddleware,
		httpPreprocess,
		httpMiddleware,
		httpPostprocess,
		logger
	)
);

export default function () {
	return createStore(
		reducer,
		enhancer
	);
}
