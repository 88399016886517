import PropTypes from 'prop-types';

const REGULAR = 'regular';
const SHARED_IN_HOUSE = 'sharedInHouse';
const SHARED_WITH_AGENCY = 'sharedWithAgency';
const asArray = [REGULAR, SHARED_IN_HOUSE, SHARED_WITH_AGENCY];

export default ({
	REGULAR,
	SHARED_IN_HOUSE,
	SHARED_WITH_AGENCY,
	asArray,
	propType: PropTypes.oneOf(asArray)
});

